import React, { useState, useEffect } from "react";
import moment from "moment";
import Dropdown from "../../common/components/dropdown";
import Pagination from "../../common/components/pagination";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import { useNavigate, useLocation } from "react-router-dom";
import ClickOutside from "../../common/components/clickOutside";
import { toast } from "react-toastify";
import Utils from "../../utility";
import { keyConstants } from "../../constants";
import { IoMdArrowUp, IoMdArrowDown } from "react-icons/io";

const List = ({
  list,
  totalPages,
  currentPage,
  handlePageChange,
  handleFirstPage,
  handleLastPage,
  loading,
  setDebouncedSearch,
  setResultFilter,
  resultFilter,
  feedbackFilter,
  setFeedbackFilter,
  setDate,
  selectTime,
  setSelectTime,
  createdOnSortBy,
  setCreatedOnSortBy,
  setProcessedOnSortBy,
  processedOnSortBy,
  date,
  topRef,
  setCurrentPage,
  versions,
  setSelectedVersion,
  selectedVersion,
  processedDate,
  setProcessedDate,
  selectProcessedTime,
  setSelectProcessedTime
}) => {
  const [open, setOpen] = useState(false);
  const [openProcessed, setOpenProcessed] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [searchClaim, setSearchClaim] = useState("");
  const [selectedRow, setSelectedRow] = useState(
    Number(sessionStorage.getItem("selectedRow")) || null
  );
  const [range, setRange] = useState({
    startDate: date?.startDate ? new Date(date?.startDate) : null,
    endDate: date?.endDate ? new Date(date?.endDate) : new Date(""),
    key: "selection"
  });
  const isWithoutFeedback =
    queryParams.get("questionText") ||
    queryParams.get("reportFeedback") ||
    queryParams.get("feedbackType");

  const [rangeProcessed, setRangeProcessed] = useState({
    startDate: processedDate?.startDate
      ? new Date(processedDate?.startDate)
      : null,
    endDate: processedDate?.endDate
      ? new Date(processedDate?.endDate)
      : new Date(""),
    key: "selection"
  });

  const navigate = useNavigate();
  const handleDateSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;

    const formattedStartDate = Utils.formatDateYear(startDate);
    const formattedEndDate = Utils.formatDateYear(endDate);

    setRange({
      ...range,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate
    });

    setDate((prev) => ({
      ...prev,
      startDate: formattedStartDate,
      endDate: formattedEndDate
    }));
    setCurrentPage(1);
  };

  const handleSelect = (value) => {
    setSelectTime(value);
    if (value === "Custom") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleDateSelectProcessed = (ranges) => {
    const { startDate, endDate } = ranges.selection;

    const formattedStartDate = Utils.formatDate(startDate);
    const formattedEndDate = Utils.formatDate(endDate);

    setRangeProcessed({
      ...rangeProcessed,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate
    });

    setProcessedDate((prev) => ({
      ...prev,
      startDate: formattedStartDate,
      endDate: formattedEndDate
    }));
    setCurrentPage(1);
  };
  const handleSelectProcessed = (value) => {
    setSelectProcessedTime(value);
    if (value === "Custom") {
      setOpenProcessed(true);
    } else {
      setOpenProcessed(false);
    }
  };

  const handleCreatedSort = () => {
    setCreatedOnSortBy((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };
  const handleProcessedSort = () => {
    setProcessedOnSortBy((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleSearch = (e) => {
    setSearchClaim(e.target.value);
  };
  const handleClearFilters = () => {
    setSelectTime("All");
    setResultFilter("All");
    setFeedbackFilter("All");
    setSearchClaim("");
    setDebouncedSearch("");
    setRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    });
    setSelectProcessedTime("All");
    setSelectedVersion("All");
    setCurrentPage(1);
  };

  const handleFeedbackText = (feedback) => {
    switch (feedback) {
      case keyConstants.CORRECT:
        return "Correct";
      case keyConstants.INCORRECT_AI_RESPONSE:
        return "Incorrect AI response";
      case keyConstants.INCORRECT_DATA_IMAGE:
        return "Incorrect data image";
      default:
        return "-";
    }
  };

  useEffect(() => {
    if (searchClaim !== "") {
      const handler = setTimeout(() => {
        setDebouncedSearch(searchClaim);
        setCurrentPage(1);
      }, 600);

      return () => clearTimeout(handler);
    } else {
      setDebouncedSearch("");
    }

    // eslint-disable-next-line
  }, [searchClaim]);

  return (
    <div className="h-full">
      <div className="flex  flex-wrap justify-between">
        <div className="flex items-center gap-2 pl-2.5">
          {isWithoutFeedback && (
            <img
              onClick={() => navigate(-1)}
              src="/images/left-arrow.svg"
              alt=""
              className="cursor-pointer"
            />
          )}
          <span className="font-InterBold text-ft25-30 text-black-10">
            Claims
          </span>
          <div className="ml-3 flex h-10 w-55 items-center gap-2 rounded-lg border  border-grey-10 bg-white p-3">
            <img
              src="/images/search-safe-guard.svg"
              alt="searchIcon"
              className="size-5 opacity-60"
            />
            <input
              type="text"
              placeholder="Claim Search"
              value={searchClaim}
              onChange={handleSearch}
              className="w-full bg-transparent font-InterRegular text-ft14-17 text-black-0 placeholder:text-grey-20 focus:outline-none"
            />
          </div>
          <ClickOutside isOpen={open} onClickOutside={() => setOpen(false)}>
            <div className="relative">
              <Dropdown
                width={"min-w-30"}
                text={"Date"}
                value={selectTime}
                list={["All", "Last 14 days", "Previous month", "Custom"]}
                handleSelect={handleSelect}
                setOpenCalendar={setOpen}
                startDate={date?.startDate}
                endDate={date?.endDate}
              />
              {open && (
                <div className="absolute z-[999]">
                  <DateRange
                    ranges={[range]}
                    onChange={handleDateSelect}
                    className="rounded-md border border-grey-30"
                    showDateDisplay={false}
                    showMonthAndYearPickers={false}
                  />
                </div>
              )}
            </div>
          </ClickOutside>
          <ClickOutside
            isOpen={openProcessed}
            onClickOutside={() => setOpenProcessed(false)}
          >
            <div className="relative">
              <Dropdown
                width={"min-w-30"}
                text={"Processed"}
                value={selectProcessedTime}
                list={["All", "Last 14 days", "Previous month", "Custom"]}
                handleSelect={handleSelectProcessed}
                setOpenCalendar={setOpenProcessed}
                startDate={processedDate?.startDate}
                endDate={processedDate?.endDate}
              />
              {openProcessed && (
                <div className="absolute z-[999]">
                  <DateRange
                    ranges={[rangeProcessed]}
                    onChange={handleDateSelectProcessed}
                    className="rounded-md border border-grey-30"
                    showDateDisplay={false}
                    showMonthAndYearPickers={false}
                  />
                </div>
              )}
            </div>
          </ClickOutside>
          <Dropdown
            width={"w-31"}
            text={"Processing Status"}
            value={resultFilter}
            list={["Open", "InProgress", "Success", "Failed"]}
            handleSelect={setResultFilter}
            setCurrentPage={setCurrentPage}
          />
          <Dropdown
            width={"min-w-31"}
            text={"Feedback"}
            value={feedbackFilter}
            list={[
              "All",
              "Incorrect AI response",
              "Incorrect data/image",
              "Correct"
            ]}
            handleSelect={setFeedbackFilter}
            setCurrentPage={setCurrentPage}
          />
          <Dropdown
            width={"min-w-31"}
            text={"Version"}
            value={selectedVersion}
            list={versions}
            handleSelect={setSelectedVersion}
            setCurrentPage={setCurrentPage}
          />
          <div
            className="flex cursor-pointer items-center gap-1"
            onClick={handleClearFilters}
          >
            <span className="font-InterRegular text-ft14-17 text-blue-10">
              Clear
            </span>
            <img src="/images/clear.svg" alt="" />
          </div>
        </div>
        <div className="flex gap-3">
          <div
            onClick={() => navigate("/repair-report")}
            className="flex h-10 cursor-pointer items-center justify-center rounded-lg border border-grey-10 p-3"
          >
            <span className="font-InterRegular text-ft14-17 ">
              Show Repair Claims
            </span>
          </div>
          {/* <div
            onClick={() => navigate("/commercial-report")}
            className="flex h-10 cursor-pointer items-center justify-center rounded-lg border border-grey-10 p-3"
          >
            <span className="font-InterRegular text-ft14-17 ">
              Show Commercial Usage
            </span>
          </div> */}

          {!isWithoutFeedback && (
            <div
              onClick={() => navigate("/report-list")}
              className="flex h-10 cursor-pointer items-center justify-center rounded-lg border border-grey-10 p-3"
            >
              <span className="font-InterRegular text-ft14-17 ">
                Show report
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="mt-5 flex justify-end">
        <span
          onClick={() => navigate("/claim-history")}
          className="cursor-pointer text-blue-30 underline"
        >
          View Old Claims
        </span>
      </div>
      {loading ? (
        <div className="flex h-80vh items-center justify-center">
          <div className="loader "></div>
        </div>
      ) : list?.length > 0 ? (
        <div>
          <div ref={topRef} className=" max-h-75vh w-full  overflow-y-auto">
            <table className="w-full">
              <thead>
                <tr className="sticky top-0  z-10 w-full bg-white font-InterBold text-ft10-18.15 text-black-10">
                  <td className="pb-2.5 pl-2.5">Claim Number</td>
                  <td>Version</td>
                  <td>Contract Number</td>
                  <td>
                    <div
                      className="flex cursor-pointer gap-1"
                      onClick={handleCreatedSort}
                    >
                      <span className="font-InterBold text-ft10-18.15  text-black-10">
                        Created On
                      </span>
                      {createdOnSortBy === "asc" ? (
                        <IoMdArrowDown />
                      ) : (
                        <IoMdArrowUp />
                      )}
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={handleProcessedSort}
                      className="flex cursor-pointer gap-1"
                    >
                      <span className="font-InterBold text-ft10-18.15  text-black-10">
                        Processed On
                      </span>
                      {processedOnSortBy === "asc" ? (
                        <IoMdArrowDown />
                      ) : (
                        <IoMdArrowUp />
                      )}
                    </div>
                  </td>
                  <td>Processing Status</td>
                  <td>Feedback</td>
                </tr>
              </thead>
              <tbody>
                {list.map((item, idx) => {
                  return (
                    <tr
                      onClick={() => {
                        if (item.processingStatus === "Success") {
                          setSelectedRow(idx);
                          sessionStorage.setItem("selectedRow", idx);

                          sessionStorage.setItem(
                            "scrollPosition",
                            topRef.current.scrollTop
                          );

                          navigate(
                            `/claim-record/${item?.claimNumber}/${item.version || 1}`
                          );
                        } else {
                          let toastId;
                          toast.dismiss(toastId);
                          if (item?.processingStatus === "DocumentMissing") {
                            toastId = toast.error(
                              "Document is incomplete for this claim."
                            );
                            return;
                          }

                          navigate(
                            `/claim-record/${item?.claimNumber}/${item.version || 1}`
                          );
                        }
                      }}
                      key={idx}
                      className={`cursor-pointer border-t border-grey-30  ${idx === selectedRow ? "bg-grey-10 bg-opacity-[.2]" : "hover:bg-grey-10 hover:bg-opacity-[.2]"}`}
                    >
                      <td className="w-15per py-3.5 pl-2.5 font-InterRegular text-ft10-18.15 text-black-10">
                        {item.claimNumber}
                      </td>
                      <td className="w-10per font-InterRegular text-ft15-18 text-black-10">
                        v{item?.version}
                      </td>
                      <td className="w-15per font-InterRegular text-ft15-18 text-black-10">
                        {item.contractNumber}
                      </td>
                      <td className="w-15per font-InterRegular text-ft15-18 text-black-10">
                        {moment(item.createdDateTime).format(
                          "DD MMM YYYY h:mm A"
                        )}
                      </td>
                      <td className="w-15per font-InterRegular text-ft15-18 text-black-10">
                        {item.processedOn
                          ? moment(item.processedOn).format(
                              "DD MMM YYYY h:mm A"
                            )
                          : "-"}
                      </td>
                      <td className="w-15per">
                        {item?.processingStatus && (
                          <div
                            className={`${
                              item.processingStatus.toLowerCase() === "success"
                                ? " bg-green-10 bg-opacity-[.2] text-green-10"
                                : item.processingStatus?.toLowerCase() ===
                                    "failed"
                                  ? "bg-red-20 text-red-10"
                                  : "bg-grey-100 bg-opacity-[.5] text-black-20 text-opacity-[.8]"
                            } w-max rounded-full  px-2.5 py-1 font-InterMedium text-ft14-17 `}
                          >
                            {Utils.convertCamelCaseToSentence(
                              item.processingStatus
                            )}
                          </div>
                        )}
                      </td>
                      <td className="w-15per">
                        {handleFeedbackText(item?.feedback)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handleFirstPage={handleFirstPage}
            handleLastPage={handleLastPage}
            handlePageChange={handlePageChange}
          />
        </div>
      ) : (
        <div className="flex h-80 items-center justify-center">
          <span className="font-InterBold text-ft24-32 text-black-0">
            No data found
          </span>
        </div>
      )}
    </div>
  );
};

export default List;
