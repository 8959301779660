import React, { useEffect, useState } from "react";
import Utils from "../../utility";

const RightPanel = ({ setImagePopup, imagesData, componentType }) => {
  const [showMoreImage, setShowMoreImage] = useState(false);
  const [showMoreDamage, setShowMoreDamage] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const damgeImagesTire = imagesData?.filter(
      (item) =>
        item?.componentCode === "LRTIRE" ||
        item?.componentCode === "RRTIRE" ||
        item?.componentCode === "LFTIRE" ||
        item?.componentCode === "RFTIRE"
    );
    const damgeImagesWheel = imagesData?.filter(
      (item) =>
        item?.componentCode === "LRWHEEL" ||
        item?.componentCode === "RRWHEEL" ||
        item?.componentCode === "LFWHEEL" ||
        item?.componentCode === "RFWHEEL"
    );
    if (componentType === "TIRE") {
      setImages(damgeImagesTire);
    } else {
      setImages(damgeImagesWheel);
    }
    //eslint-disable-next-line
  }, [componentType, imagesData]);

  const documentImages = imagesData?.filter(
    (item) => item.type !== "damage" && item?.type !== "commercialLicensePlates"
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-5 rounded-lg bg-white p-4">
        <h1 className="font-InterSemiBold text-ft20-28 text-black-10">
          Document Images
        </h1>
        <div className="grid size-full grid-cols-2 gap-5 pt-4.5">
          {documentImages?.map((item, idx) => {
            if (idx > 3 && !showMoreImage) return;
            return (
              <div key={idx} className="flex w-full flex-col gap-2">
                <div
                  onClick={() => {
                    if (item.image) {
                      setImagePopup((prev) => ({
                        ...prev,
                        popup: true,
                        allImages: imagesData,
                        idx: idx
                      }));
                    }
                  }}
                  className="relative w-full cursor-pointer"
                >
                  {item.image?.toLowerCase()?.includes(".pdf") ? (
                    <img
                      src="/images/document.svg"
                      alt=""
                      className="h-32 w-full rounded-lg"
                    />
                  ) : item.image ? (
                    <img
                      src={item.image}
                      alt=""
                      className="h-32 w-full rounded-lg"
                    />
                  ) : (
                    <div className="flex h-32 w-full flex-col items-center justify-center gap-3 rounded-lg bg-grey-90 text-center">
                      <img src="/images/image-logo-light.svg" alt="logo" />
                      <span className="w-20 font-InterRegular text-ft12-16 text-grey-20">
                        No Images attached
                      </span>
                    </div>
                  )}
                  <div className="absolute bottom-2 right-1">
                    <img src="/images/zoom.svg" alt="" />
                  </div>
                </div>
                <span className="w-max rounded-[4px] border border-grey-70 bg-grey-80 px-2 py-1 font-InterRegular text-ft12-16 text-black-0">
                  {Utils.formatText(item.componentName)}
                </span>
              </div>
            );
          })}
        </div>
        {documentImages?.length > 4 && (
          <div
            onClick={() => setShowMoreImage(!showMoreImage)}
            className="cursor-pointer text-center font-InterRegular text-ft14-17 text-red-10"
          >
            {!showMoreImage ? "Show more" : "Show less"}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-4 rounded-lg bg-white p-4">
        <h1 className="font-InterSemiBold text-ft20-28 text-black-10">
          Damage Images
        </h1>
        <div className="grid size-full grid-cols-2 gap-5 pt-4.5">
          {images?.map((item, idx) => {
            if (idx > 3 && !showMoreDamage) return;
            return (
              <div key={idx} className="flex w-full flex-col gap-2">
                <div
                  onClick={() => {
                    if (item.image) {
                      setImagePopup((prev) => ({
                        ...prev,
                        popup: true,
                        allImages: imagesData,
                        idx: documentImages?.length + idx
                      }));
                    }
                  }}
                  className="relative w-full cursor-pointer"
                >
                  {item.image?.toLowerCase()?.includes(".pdf") ? (
                    <img
                      src="/images/document.svg"
                      alt=""
                      className="h-32 w-full rounded-lg"
                    />
                  ) : item.image ? (
                    <img
                      src={item.image}
                      alt=""
                      className="h-32 w-full rounded-lg"
                    />
                  ) : (
                    <div className="flex h-32 w-full flex-col items-center justify-center gap-3 rounded-lg bg-grey-90 text-center">
                      <img src="/images/image-logo-light.svg" alt="logo" />
                      <span className="w-20 font-InterRegular text-ft12-16 text-grey-20">
                        No Images attached
                      </span>
                    </div>
                  )}
                  <div className="absolute bottom-2 right-1">
                    <img src="/images/zoom.svg" alt="" />
                  </div>
                </div>
                <span className="w-max rounded-[4px] border border-grey-70 bg-grey-80 px-2 py-1 font-InterRegular text-ft12-16 text-black-0">
                  {Utils.formatText(item.componentName)}
                </span>
              </div>
            );
          })}
        </div>
        {images?.length > 4 && (
          <div
            onClick={() => setShowMoreDamage(!showMoreDamage)}
            className="cursor-pointer text-center font-InterRegular text-ft14-17 text-red-10"
          >
            {!showMoreDamage ? "Show more" : "Show less"}
          </div>
        )}
      </div>
    </div>
  );
};

export default RightPanel;
