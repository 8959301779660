import React, { useState, useEffect } from "react";
import Utils from "../../utility";
import { getReportList } from "../../services/zbrainConnectorService";
import { useLocation, useNavigate } from "react-router-dom";
import Report from "./report";

const ReportListComponent = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const navigate = useNavigate();
  const [report, setReport] = useState({});
  const [date, setDate] = useState({
    startDate: queryParams.get("toDate") || null,
    endDate: queryParams.get("fromDate") || null
  });
  const [selectTime, setSelectTime] = useState(
    queryParams.get("selectTime") || "All"
  );
  const [processedDate, setProcessedDate] = useState({
    startDate: queryParams.get("processedOnTo") || null,
    endDate: queryParams.get("processedOnFrom") || null
  });

  const [selectProcessedTime, setSelectProcessedTime] = useState(
    queryParams.get("processedTime") || "All"
  );

  const getClaims = async () => {
    try {
      let requesData = {};

      if (selectTime !== "Custom" && selectTime !== "All") {
        const { startDate, endDate } = Utils.getDateRange(selectTime);
        requesData.fromDate = startDate;
        requesData.toDate = endDate;
      }
      if (date.startDate && date.endDate && selectTime === "Custom") {
        requesData.fromDate = date.startDate;
        requesData.toDate = date.endDate;
      }
      if (selectProcessedTime !== "Custom" && selectProcessedTime !== "All") {
        const { startDate, endDate } =
          Utils.getDateRangeProcessed(selectProcessedTime);
        requesData.processedOnFrom = startDate;
        requesData.processedOnTo = endDate;
      }
      if (
        processedDate.startDate &&
        processedDate.endDate &&
        selectProcessedTime === "Custom"
      ) {
        const startDateObject = new Date(
          processedDate.startDate.split("-").reverse().join("-")
        );
        const startUTCDate = Date.UTC(
          startDateObject.getUTCFullYear(),
          startDateObject.getUTCMonth(),
          startDateObject.getUTCDate(),
          0,
          0,
          0,
          0
        );
        requesData.processedOnFrom = startUTCDate;
        let endDateObject = new Date(
          processedDate.endDate.split("-").reverse().join("-")
        );
        const endUTCDate = Date.UTC(
          endDateObject.getUTCFullYear(),
          endDateObject.getUTCMonth(),
          endDateObject.getUTCDate(),
          23,
          59,
          59,
          999
        );

        requesData.processedOnTo = endUTCDate;
      }
      const [error, response] = await Utils.parseResponse(
        getReportList(requesData)
      );

      if (error || !response) {
        return;
      }

      setReport(response);
    } catch (e) {
      console.error(e);
    }
  };

  const updateDateParams = () => {
    if (
      date?.endDate !== null &&
      date?.startDate !== null &&
      selectTime === "Custom"
    ) {
      queryParams.set("toDate", date.startDate);
      queryParams.set("fromDate", date.endDate);
    } else {
      queryParams.delete("toDate");
      queryParams.delete("fromDate");
    }
  };

  const updateProcessedParams = () => {
    if (
      processedDate?.endDate !== null &&
      processedDate?.startDate !== null &&
      selectProcessedTime === "Custom"
    ) {
      queryParams.set("processedOnTo", processedDate.startDate);
      queryParams.set("processedOnFrom", processedDate.endDate);
    } else {
      queryParams.delete("processedOnTo");
      queryParams.delete("processedOnFrom");
    }
  };

  useEffect(() => {
    updateDateParams();
    queryParams.set("selectTime", selectTime);
    updateProcessedParams();
    queryParams.set("processedTime", selectProcessedTime);
    navigate(
      {
        search: queryParams.toString()
      },
      { replace: true }
    );
    getClaims();
  }, [selectTime, selectProcessedTime, date, processedDate]);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-wrap items-center gap-2">
        <img
          onClick={() => navigate("/claim-list")}
          src="/images/left-arrow.svg"
          alt=""
          className="cursor-pointer"
        />
        <span className="font-InterBold text-ft25-30 text-black-10">
          Report
        </span>
      </div>
      <Report
        date={date}
        processedDate={processedDate}
        report={report}
        selectProcessedTime={selectProcessedTime}
        selectTime={selectTime}
        setDate={setDate}
        setProcessedDate={setProcessedDate}
        setSelectProcessedTime={setSelectProcessedTime}
        setSelectTime={setSelectTime}
      />
    </div>
  );
};

export default ReportListComponent;
