import React, { useState, useEffect } from "react";
import FeedbackPopup from "../../common/components/popup/feedbackPopup";
import { keyConstants, tireComponent } from "../../constants";
import Utils from "../../utility";
import { LuThumbsUp, LuThumbsDown } from "react-icons/lu";
import { FaThumbsUp } from "react-icons/fa6";

const ComponentTable = ({
  thead,
  tbody,
  setImagePopup,
  setOpenReasonPopup,
  openReasonPopup,
  setHoverId,
  hoverId,
  imagesData,
  handleFeedback,
  handleUpdateFeedback,
  feedback,
  questions,
  setComponentFeedback,
  componentFeedback
}) => {
  const [checks, setChecks] = useState(true);
  const [isCorrect, setIsCorrect] = useState(false);

  const leftRearTire = imagesData?.filter(
    (item) => item.componentName === tireComponent.leftRearTire
  );
  const leftFrontTire = imagesData?.filter(
    (item) => item.componentName === tireComponent.leftFrontTire
  );
  const rightRearTire = imagesData?.filter(
    (item) => item.componentName === tireComponent.rightRearTire
  );
  const rightFrontTire = imagesData?.filter(
    (item) => item.componentName === tireComponent.rightFrontTire
  );

  const leftRearWheel = imagesData?.filter(
    (item) => item.componentName === tireComponent.leftRearWheel
  );
  const leftFrontWheel = imagesData?.filter(
    (item) => item.componentName === tireComponent.leftFrontWheel
  );
  const rightRearWheel = imagesData?.filter(
    (item) => item.componentName === tireComponent.rightRearWheel
  );
  const rightFrontWheel = imagesData?.filter(
    (item) => item.componentName === tireComponent.rightFrontWheel
  );

  const handleImageType = (name) => {
    let allImages = [];
    switch (name) {
      case tireComponent.leftRearTire:
        allImages = leftRearTire;
        break;
      case tireComponent.rightRearTire:
        allImages = rightRearTire;
        break;
      case tireComponent.leftFrontTire:
        allImages = leftFrontTire;
        break;
      case tireComponent.rightFrontTire:
        allImages = rightFrontTire;
        break;
      case tireComponent.leftRearWheel:
        allImages = leftRearWheel;
        break;
      case tireComponent.rightRearWheel:
        allImages = rightRearWheel;
        break;
      case tireComponent.leftFrontWheel:
        allImages = leftFrontWheel;
        break;
      case tireComponent.rightFrontWheel:
        allImages = rightFrontWheel;
        break;
      default:
        allImages = [];
    }
    return allImages;
  };

  const mandatoryChecksStatus = (checkValues) => {
    const sameTireCheck = checkValues?.find(
      (check) =>
        check?.checkName?.toLowerCase() === "sametire" &&
        check?.checkValue?.toLowerCase() === "yes"
    );
    const otherChecksAreNo = checkValues?.every((check) => {
      if (check?.checkName?.toLowerCase() !== "sametire") {
        return check?.checkValue?.toLowerCase() === "no";
      }
      return true;
    });
    if (sameTireCheck && otherChecksAreNo) {
      return "Approved";
    } else {
      return "Denied";
    }
  };

  const handleMandatoryCheckFeedback = (
    item,
    childItem,
    feedbackPresent,
    feedbackType,
    isCorrect
  ) => {
    setComponentFeedback((prev) => ({
      ...prev,
      componentName: "",
      questionText: "",
      generatedResponse: "",
      feedback: "",
      comment: "",
      feedbackType: ""
    }));
    if (!isCorrect) {
      setOpenReasonPopup(childItem?._id);
    }
    setComponentFeedback((prev) => ({
      ...prev,
      questionText: childItem?.checkName || childItem?.questionText,
      feedback: isCorrect ? keyConstants.CORRECT : feedbackPresent?.feedback,
      comment: feedbackPresent?.comment,
      componentName: item.componentName,
      generatedResponse: childItem?.checkValue || childItem?.answer,
      feedbackType
    }));
  };
  const ClearData = () => {
    setComponentFeedback((prev) => ({
      ...prev,
      componentName: "",
      questionText: "",
      generatedResponse: "",
      feedback: "",
      comment: "",
      feedbackType: ""
    }));
  };

  const handleFeedbackForComponent = (
    item,
    feedbackType,
    feedbackPresent,
    isCorrect
  ) => {
    setComponentFeedback((prev) => ({
      ...prev,
      componentName: "",
      questionText: "",
      generatedResponse: "",
      feedback: "",
      comment: ""
    }));
    if (!isCorrect) {
      setOpenReasonPopup(item?._id);
    }
    setComponentFeedback((prev) => ({
      ...prev,
      questionText: item?.element,
      feedback: isCorrect ? keyConstants.CORRECT : feedbackPresent?.feedback,
      comment: feedbackPresent?.comment,
      componentName: item?.componentName,
      generatedResponse: item?.systemResults,
      feedbackType
    }));
  };

  useEffect(() => {
    if (isCorrect) {
      if (feedback) {
        handleUpdateFeedback();
      } else {
        handleFeedback();
      }
    }
    setIsCorrect(false);
  }, [isCorrect]);

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr className="font-InterBold text-ft15-18 text-black-10">
            {thead.map((item, idx) => {
              return (
                <td
                  key={idx}
                  className={` bg-darkGrey-30 py-3.5  pl-3.5 ${idx != 0 && "border-l border-grey-60"}`}
                >
                  <div className="flex items-center gap-1">
                    <span
                      className={`whitespace-nowrap ${
                        item.name === "Mismatched"
                          ? " text-red-10"
                          : item.name === "Matched"
                            ? "font-InterMedium text-green-10"
                            : ""
                      }`}
                    >
                      {item.name}
                    </span>

                    {item.isMoreDetailsAvaialble && (
                      <div className="relative w-full">
                        <img
                          src={
                            item.isMoreDetailsAvaialble &&
                            item.name === "Approved"
                              ? "/images/green-check.svg"
                              : "/images/red-checkMark.svg"
                          }
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tbody?.map((item, idx) => {
            const questionValues = questions[item?.componentName] || [];
            const filterQuestionText = questionValues?.filter(
              (item) => item.questionText !== ""
            );

            const mandatoryCheck = mandatoryChecksStatus(item.mandatoryChecks);

            return (
              <>
                {!item?.isMoreDetailsAvaialble && (
                  <tr key={idx} className="border-t border-grey-30 bg-white">
                    <td className="w-30per py-3.5 pl-3.5 font-InterRegular text-ft10-18.15 text-grey-50">
                      <div>{item.element}</div>
                    </td>
                    <td className="w-25per border-l border-grey-60 pl-3.5 font-InterRegular text-ft10-18.15 text-black-10">
                      <div>
                        {item.claimData?.toLowerCase() === "n"
                          ? "No"
                          : item?.claimData?.toLowerCase() === "y"
                            ? "Yes"
                            : item.claimData}
                      </div>
                    </td>
                    <td
                      onMouseEnter={() => {
                        if (!openReasonPopup) setHoverId(item._id);
                      }}
                      onMouseLeave={() => {
                        if (!openReasonPopup) setHoverId(null);
                      }}
                      className="w-30per border-l border-grey-60 px-3.5 font-InterRegular text-ft10-18.15 text-black-10"
                    >
                      <div className="flex items-center justify-between">
                        <span>
                          {item.systemResults?.toLowerCase() === "n"
                            ? "No"
                            : item.systemResults?.toLowerCase() === "y"
                              ? "Yes"
                              : item.systemResults}
                        </span>
                        {item.feedback && (
                          <div className="relative flex gap-3">
                            {hoverId === item._id && item?.feedback && (
                              <img
                                onClick={() =>
                                  setImagePopup((prev) => ({
                                    ...prev,
                                    popup: true,
                                    allImages: handleImageType(
                                      item.componentName
                                    ),
                                    idx: 0
                                  }))
                                }
                                src="/images/image-logo.svg"
                                alt=""
                                className="cursor-pointer"
                              />
                            )}
                            {item?.feedback?.feedback !== "CORRECT" ? (
                              <img
                                onClick={() => {
                                  setOpenReasonPopup(item._id);
                                  handleFeedbackForComponent(
                                    item,
                                    "damageResults",
                                    item?.feedback
                                  );
                                }}
                                src="/images/red-thumbs-down.svg"
                                alt=""
                                className="cursor-pointer"
                              />
                            ) : (
                              <FaThumbsUp
                                onClick={() => {
                                  setOpenReasonPopup(item._id);
                                  handleFeedbackForComponent(
                                    item,
                                    "damageResults",
                                    item?.feedback
                                  );
                                }}
                                className="cursor-pointer"
                              />
                            )}
                            {openReasonPopup === item._id && (
                              <FeedbackPopup
                                setState={() => setOpenReasonPopup(null)}
                                elementData={componentFeedback}
                                setElementData={setComponentFeedback}
                                handleFeedback={handleFeedback}
                                handleUpdateFeedback={handleUpdateFeedback}
                                edit={true}
                                clearData={ClearData}
                              />
                            )}
                          </div>
                        )}
                        {hoverId === item._id && !item?.feedback && (
                          <div className="relative flex gap-3">
                            <img
                              onClick={() =>
                                setImagePopup((prev) => ({
                                  ...prev,
                                  popup: true,
                                  allImages: handleImageType(
                                    item.componentName
                                  ),
                                  idx: 0
                                }))
                              }
                              src="/images/image-logo.svg"
                              alt=""
                              className="cursor-pointer"
                            />
                            <LuThumbsUp
                              className="cursor-pointer"
                              onClick={() => {
                                setIsCorrect(true);
                                handleFeedbackForComponent(
                                  item,
                                  "damageResults",
                                  null,
                                  true
                                );
                              }}
                            />

                            <LuThumbsDown
                              className="cursor-pointer"
                              onClick={() => {
                                setOpenReasonPopup(item._id);
                                setIsCorrect(false);
                                handleFeedbackForComponent(
                                  item,
                                  "damageResults"
                                );
                              }}
                            />
                            {openReasonPopup === item._id && (
                              <FeedbackPopup
                                setState={() => setOpenReasonPopup(null)}
                                elementData={componentFeedback}
                                setElementData={setComponentFeedback}
                                handleFeedback={handleFeedback}
                                handleUpdateFeedback={handleUpdateFeedback}
                                callUpdate={feedback ? true : false}
                                isForCorrect={isCorrect}
                                clearData={ClearData}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                    <td
                      className={`text-black-1 w-25per border-l border-grey-60 pl-3.5 font-InterRegular text-ft10-18.15 ${
                        item?.result === "Mismatched"
                          ? " text-red-10"
                          : " text-green-10"
                      }`}
                    >
                      {item.result}
                    </td>
                    {/* <td className="w-15per border-l border-grey-60 p-3.5">
                      <div
                        className={`${
                          item.result === "Mismatched"
                            ? " text-red-10"
                            : " text-green-10"
                        } rounded-full font-InterMedium text-ft15-18`}
                      >
                        {item.result}
                      </div>
                    </td> */}
                  </tr>
                )}
                {item.isMoreDetailsAvaialble && (
                  <tr className="border-t border-grey-60 bg-white">
                    <td colSpan="4">
                      <div className=" flex w-full gap-3 px-4 py-2">
                        <div
                          onClick={() => setChecks(true)}
                          className={`flex h-8 w-max cursor-pointer items-center justify-center rounded-md border p-2 text-ft14-17 ${checks ? "border-black-0 text-black-10" : "border-grey-30 text-black-20"}`}
                        >
                          <span className=" font-InterMedium">Checks</span>
                        </div>
                        <div
                          onClick={() => setChecks(false)}
                          className={`flex h-8 w-max cursor-pointer items-center justify-center rounded-md border p-2 text-ft14-17 ${!checks ? "border-black-0 text-black-10" : "border-grey-30 text-black-20"}`}
                        >
                          <span className=" font-InterMedium">Description</span>
                        </div>
                      </div>

                      {checks ? (
                        <div className="flex w-full flex-col">
                          {item.mandatoryChecks?.length > 0 && (
                            <div className="w-full px-4 py-2">
                              <div className="flex w-full justify-between">
                                <span className="font-InterRegular text-ft14-17  text-black-20">
                                  Mandatory Checks
                                </span>
                                <span
                                  className={`font-InterRegular text-ft14-17 ${mandatoryCheck === "Approved" ? "text-green-10" : "text-red-10"} `}
                                >
                                  {mandatoryCheck}
                                </span>
                              </div>

                              <div className="flex flex-col gap-3 rounded-md bg-grey-110 p-4">
                                {item.mandatoryChecks?.map((itm, idx) => {
                                  const feedbackPresent =
                                    item?.mandatoryFeedback?.find(
                                      (value) =>
                                        value.questionText === itm?.checkName &&
                                        value.componentName ===
                                          item?.componentName
                                    );

                                  return (
                                    <div
                                      key={idx}
                                      className="flex justify-between"
                                    >
                                      <span className="font-InterRegular text-ft14-17 text-black-10">
                                        {itm.checkName
                                          .replace(/([A-Z])/g, " $1")
                                          .trim()
                                          .replace(/^./, (str) =>
                                            str.toUpperCase()
                                          )}
                                      </span>
                                      <div className="flex gap-2">
                                        <span className="font-InterMedium text-ft14-17 text-black-10">
                                          {itm?.checkValue === "y"
                                            ? "Yes"
                                            : itm?.checkValue === "n"
                                              ? "No"
                                              : Utils.formatText(
                                                  itm.checkValue
                                                )}
                                        </span>
                                        <div className="relative cursor-pointer">
                                          {feedbackPresent ? (
                                            feedbackPresent?.feedback !==
                                            "CORRECT" ? (
                                              <img
                                                onClick={() => {
                                                  setIsCorrect(false);
                                                  handleMandatoryCheckFeedback(
                                                    item,
                                                    itm,
                                                    feedbackPresent,
                                                    "mandatoryChecks"
                                                  );
                                                }}
                                                src="/images/red-thumbs-down.svg"
                                                alt=""
                                                className="cursor-pointer"
                                              />
                                            ) : (
                                              <FaThumbsUp
                                                onClick={() => {
                                                  setIsCorrect(false);
                                                  handleMandatoryCheckFeedback(
                                                    item,
                                                    itm,
                                                    feedbackPresent,
                                                    "mandatoryChecks"
                                                  );
                                                }}
                                              />
                                            )
                                          ) : (
                                            <div className="flex gap-2">
                                              <LuThumbsUp
                                                onClick={() => {
                                                  setIsCorrect(true);
                                                  handleMandatoryCheckFeedback(
                                                    item,
                                                    itm,
                                                    feedbackPresent,
                                                    "mandatoryChecks",
                                                    true
                                                  );
                                                }}
                                              />
                                              <LuThumbsDown
                                                className="cursor-pointer"
                                                onClick={() => {
                                                  setIsCorrect(false);
                                                  handleMandatoryCheckFeedback(
                                                    item,
                                                    itm,
                                                    feedbackPresent,
                                                    "mandatoryChecks"
                                                  );
                                                }}
                                              />
                                            </div>
                                          )}
                                          {itm?._id === openReasonPopup && (
                                            <FeedbackPopup
                                              align="right-0"
                                              elementData={componentFeedback}
                                              setElementData={
                                                setComponentFeedback
                                              }
                                              handleFeedback={handleFeedback}
                                              setState={() =>
                                                setOpenReasonPopup(null)
                                              }
                                              handleUpdateFeedback={
                                                handleUpdateFeedback
                                              }
                                              isForCorrect={isCorrect}
                                              edit={
                                                feedbackPresent ? true : false
                                              }
                                              callUpdate={
                                                feedback ? true : false
                                              }
                                              clearData={ClearData}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                          {filterQuestionText?.length > 0 && (
                            <div className="w-full  px-4 py-2">
                              <div className="flex w-full justify-between">
                                <span className="font-InterRegular text-ft14-17 text-black-20">
                                  Additional Questions
                                </span>
                                <span
                                  className={`font-InterRegular text-ft14-17 ${item?.systemResult === "Approved" ? "text-green-10" : "text-red-10"} `}
                                >
                                  {item?.systemResult}
                                </span>
                              </div>

                              <div className="flex flex-col gap-3 rounded-md bg-grey-110 p-4">
                                {filterQuestionText?.map((itm, idx) => {
                                  const feedbackPresent =
                                    item?.additionalFeedback?.find(
                                      (value) =>
                                        value.questionText ===
                                          itm?.questionText &&
                                        value.componentName ===
                                          item?.componentName
                                    );

                                  return (
                                    <div
                                      key={idx}
                                      className="flex justify-between"
                                    >
                                      <span className="w-85per font-InterRegular text-ft14-17 text-black-10">
                                        {itm.questionText}
                                      </span>
                                      <div className="flex gap-2">
                                        <span className="font-InterMedium text-ft14-17 text-black-10">
                                          {itm?.answer === "y"
                                            ? "Yes"
                                            : itm?.answer === "n"
                                              ? "No"
                                              : Utils.formatText(itm.answer)}
                                        </span>
                                        <div className="relative cursor-pointer">
                                          {feedbackPresent ? (
                                            feedbackPresent?.feedback !==
                                            "CORRECT" ? (
                                              <img
                                                onClick={() => {
                                                  setIsCorrect(false);
                                                  handleMandatoryCheckFeedback(
                                                    item,
                                                    itm,
                                                    feedbackPresent,
                                                    "additionalQuestions"
                                                  );
                                                }}
                                                src="/images/red-thumbs-down.svg"
                                                alt=""
                                                className="cursor-pointer"
                                              />
                                            ) : (
                                              <FaThumbsUp
                                                onClick={() => {
                                                  setIsCorrect(false);
                                                  handleMandatoryCheckFeedback(
                                                    item,
                                                    itm,
                                                    feedbackPresent,
                                                    "additionalQuestions"
                                                  );
                                                }}
                                              />
                                            )
                                          ) : (
                                            <div className="flex gap-2">
                                              <LuThumbsUp
                                                onClick={() => {
                                                  setIsCorrect(true);
                                                  handleMandatoryCheckFeedback(
                                                    item,
                                                    itm,
                                                    feedbackPresent,
                                                    "additionalQuestions",
                                                    true
                                                  );
                                                }}
                                                className="cursor-pointer"
                                              />
                                              <LuThumbsDown
                                                onClick={() => {
                                                  setIsCorrect(false);
                                                  handleMandatoryCheckFeedback(
                                                    item,
                                                    itm,
                                                    feedbackPresent,
                                                    "additionalQuestions"
                                                  );
                                                }}
                                                className="cursor-pointer"
                                              />
                                            </div>
                                          )}
                                          {itm?._id === openReasonPopup && (
                                            <FeedbackPopup
                                              align="right-0"
                                              elementData={componentFeedback}
                                              setElementData={
                                                setComponentFeedback
                                              }
                                              handleFeedback={handleFeedback}
                                              setState={() =>
                                                setOpenReasonPopup(null)
                                              }
                                              handleUpdateFeedback={
                                                handleUpdateFeedback
                                              }
                                              edit={
                                                feedbackPresent ? true : false
                                              }
                                              callUpdate={
                                                feedback ? true : false
                                              }
                                              clearData={ClearData}
                                              isForCorrect={isCorrect}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="mx-4 my-2 h-full rounded-md bg-grey-110 p-4 font-InterRegular text-ft14-17  text-black-10">
                          <span>{item.reason}</span>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ComponentTable;
