import React, { useEffect, useState } from "react";
import CommercialFlowsResp from "./commercialFlowResp";
import ComponentTable from "./componentTable";
import VinFlowResp from "./vinFlowResp";

const ComparisonList = ({
  imagesData,
  setImagePopup,
  openReasonPopup,
  handleFeedback,
  setOpenReasonPopup,
  data,
  handleUpdateFeedback,
  setQuestions,
  questions,
  setComponentType,
  componentType,
  localFeedbackData,
  setComponentFeedback,
  componentFeedback,
  setCheckFeedback,
  checkFeedback
}) => {
  const [hoverId, setHoverId] = useState(null);
  const [comparisonData, setComparisonData] = useState([]);
  const [vinData, setVinData] = useState(null);

  let uniqueIdCounter = 1;

  const extractVindata = (vehicleData) => {
    setVinData(vehicleData);
  };

  const mapComponentsToTableData = (components) => {
    return components?.map((component) => {
      const claimDataApprove = component?.claimDataStatus;

      const isSystemResultApproved = component?.componentStatus;
      const result =
        claimDataApprove?.toLowerCase() ===
        isSystemResultApproved?.toLowerCase()
          ? "Matched"
          : "Mismatched";

      const thead = [
        {
          name: component.componentName
        },
        {
          name: claimDataApprove
        },
        {
          name: isSystemResultApproved,
          componentName: component.componentName,
          componentId: component?._id,
          isMoreDetailsAvaialble: true,
          reason: component?.componentDescription,
          feedback: localFeedbackData?.checks
        },
        { name: result }
      ];

      const tbody = [];
      const damageDescription = component?.componentAdditionalQuestions;

      if (damageDescription) {
        if (component.isDamageIdentified) {
          setQuestions((prev) => ({
            ...prev,
            [component.componentName]: damageDescription
          }));

          const matchingFeedback = localFeedbackData?.damageResults?.find(
            (feedback) =>
              feedback.componentName === component.componentName &&
              "Tire Damage Description" === feedback?.questionText
          );

          tbody.push({
            _id: uniqueIdCounter++,
            element: `${componentType === "TIRE" ? "Tire" : "Wheel"} Damage Description`,
            componentName: component.componentName,
            claimData:
              component.tireDamageDescription ||
              component?.wheelDamageDescription ||
              "-",
            systemResults: `${component.tireDamageDescription || component?.wheelDamageDescription || "-"} ${
              component?.isDamageIdentified === "Yes"
                ? "identified"
                : "not identified"
            }`,
            result:
              component?.tireDamageDescription ===
              component?.tireDamageDescription
                ? "Matched"
                : "Mismatched",

            feedback: matchingFeedback ? matchingFeedback : ""
          });
          if (
            component?.claimDataDamageLocation !== null &&
            (component?.tireDamageDescription === "Hole" ||
              component?.tireDamageDescription ===
                "Hole Too Large for Repair" ||
              component?.tireDamageDescription === "Puncture Hole" ||
              component?.tireDamageDescription === "Puncture in Tread" ||
              component?.tireDamageDescription === "Puncture in Sidewall")
          ) {
            const matchingFeedbackLocation =
              localFeedbackData?.damageResults?.find(
                (feedback) =>
                  feedback.componentName === component.componentName &&
                  "Tire Damage Location" === feedback?.questionText
              );
            tbody.push({
              _id: uniqueIdCounter++,
              element: `${componentType === "TIRE" ? "Tire" : "Wheel"} Damage Location`,
              componentName: component.componentName,
              claimData: component?.claimDataDamageLocation || "-",
              systemResults: component?.componentDamageLocation || "-",
              result:
                component?.claimDataDamageLocation?.toLowerCase() ===
                component?.componentDamageLocation?.toLowerCase()
                  ? "Matched"
                  : "Mismatched",

              feedback: matchingFeedbackLocation ? matchingFeedbackLocation : ""
            });
          }
        }
      }
      const matchingFeedback = localFeedbackData?.damageResults?.find(
        (feedback) =>
          feedback.componentName === component.componentName &&
          "Corrective Action" === feedback?.questionText
      );

      tbody.push({
        _id: uniqueIdCounter++,
        element: "Corrective Action",
        componentName: component.componentName,
        claimData: component?.claimDataAction || "-",
        systemResults: component.componentAction || "-",
        result:
          component?.claimDataAction === component?.componentAction
            ? "Matched"
            : "Mismatched",
        feedback: matchingFeedback ? matchingFeedback : ""
      });
      tbody.push({
        mandatoryChecks: component?.mandatoryChecks,
        componentName: component.componentName,
        isMoreDetailsAvaialble: true,
        reason: component?.componentDescription,
        systemResult: isSystemResultApproved,
        mandatoryFeedback: localFeedbackData?.mandatoryChecks,
        additionalFeedback: localFeedbackData?.additionalQuestions,
        componentId: component?._id
      });

      return { thead, tbody };
    });
  };

  useEffect(() => {
    extractVindata(data?.vinComparisonResults?.vinComparisonSystemResults);
    //eslint-disable-next-line
  }, [data, localFeedbackData]);

  useEffect(() => {
    let tableData;
    const componentTireData = data?.components?.filter(
      (item) => item.componentType?.toLowerCase() === "tire"
    );

    const componentWheelData = data?.components?.filter(
      (item) => item.componentType?.toLowerCase() === "wheel"
    );

    if (componentType === "TIRE") {
      tableData = mapComponentsToTableData(
        componentTireData,
        data.feedback || []
      );
    } else {
      tableData = mapComponentsToTableData(
        componentWheelData,
        data.feedback || []
      );
    }

    setComparisonData(tableData);
  }, [data, componentType, localFeedbackData]);

  return (
    <div>
      <div className="w-full ">
        <div>
          <CommercialFlowsResp
            data={data}
            handleFeedback={handleFeedback}
            imagesData={imagesData}
            setImagePopup={setImagePopup}
            handleUpdateFeedback={handleUpdateFeedback}
            feedback={localFeedbackData}
            openReasonPopup={openReasonPopup}
            setOpenReasonPopup={setOpenReasonPopup}
            setCheckFeedback={setCheckFeedback}
            checkFeedback={checkFeedback}
          />
        </div>
        <div className="my-3">
          <VinFlowResp
            data={data}
            handleFeedback={handleFeedback}
            imagesData={imagesData}
            setImagePopup={setImagePopup}
            handleUpdateFeedback={handleUpdateFeedback}
            feedback={localFeedbackData}
            openReasonPopup={openReasonPopup}
            setOpenReasonPopup={setOpenReasonPopup}
            vinData={vinData}
            setCheckFeedback={setCheckFeedback}
            checkFeedback={checkFeedback}
          />
        </div>
        <div className=" my-3 flex h-10 items-center gap-5">
          {data?.components?.find(
            (item) => item.componentType?.toLowerCase() === "tire"
          ) && (
            <span
              onClick={() => setComponentType("TIRE")}
              className={` ${componentType === "TIRE" ? "border border-green-10 bg-green-10" : "border border-grey-10 bg-white"} w-20 cursor-pointer rounded-lg bg-opacity-10 py-2 text-center font-InterMedium text-ft16-24 text-black-0`}
            >
              Tire
            </span>
          )}
          {data?.components?.find(
            (item) => item.componentType?.toLowerCase() === "wheel"
          ) && (
            <span
              onClick={() => setComponentType("WHEEL")}
              className={` ${componentType === "WHEEL" ? "border border-green-10 bg-green-10" : "border border-grey-10 bg-white"} w-20 cursor-pointer rounded-lg bg-opacity-10 py-2 text-center font-InterMedium text-ft16-24 text-black-0`}
            >
              Wheel
            </span>
          )}
        </div>
        {comparisonData?.map((item, idx) => (
          <div key={idx}>
            <ComponentTable
              openReasonPopup={openReasonPopup}
              setImagePopup={setImagePopup}
              setOpenReasonPopup={setOpenReasonPopup}
              tbody={item.tbody}
              thead={item.thead}
              hoverId={hoverId}
              imagesData={imagesData}
              setHoverId={setHoverId}
              handleFeedback={handleFeedback}
              handleUpdateFeedback={handleUpdateFeedback}
              feedback={localFeedbackData}
              questions={questions}
              setComponentFeedback={setComponentFeedback}
              componentFeedback={componentFeedback}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComparisonList;
