import React, { useState, useEffect } from "react";
import FeedbackPopup from "../../common/components/popup/feedbackPopup";
import { LuThumbsUp, LuThumbsDown } from "react-icons/lu";
import { FaThumbsUp } from "react-icons/fa6";
import { v4 as uuidv4 } from "uuid";
import { keyConstants } from "../../constants";

const CommercialFlowsResp = ({
  data,
  elementData,
  setElementData,
  handleFeedback,
  imagesData,
  setImagePopup,
  handleUpdateFeedback,
  feedback,
  setOpenReasonPopup,
  openReasonPopup,
  setCheckData
}) => {
  const [checks, setChecks] = useState(true);
  const [isCorrect, setIsCorrect] = useState(false);

  const license = imagesData?.filter(
    (item) => item.type === "commercialLicensePlates"
  );

  const isCommerciallyUsedFeedback = feedback?.elementsFeedback?.find(
    (item) => item.elementName === "Is commercial usage allowed?"
  );
  const isUsedCommerciallyFeedback = feedback?.elementsFeedback?.find(
    (item) => item.elementName === "Is used commercially?"
  );

  const handleFeedbackFn = (data, feedbackText, feedbackType, isCorrect) => {
    setCheckData((prev) => ({
      ...prev,
      componentName: "",
      componentId: "",
      checkName: "",
      feedback: "",
      comment: "",
      checkId: "",
      systemResult: ""
    }));
    if (!isCorrect) {
      setOpenReasonPopup(feedbackText);
    }
    setElementData((prev) => ({
      ...prev,
      elementId: feedbackType?.elementId || uuidv4(),
      claimData: data?.vehicleData?.commercialUsageAllowed?.claimData || "",
      systemResult: data?.vehicleData?.commercialUsageAllowed?.systemData || "",
      elementName: feedbackText,
      feedback: isCorrect ? keyConstants.CORRECT : feedbackType?.feedback || "",
      comment: feedbackType?.comment || ""
    }));
  };

  const handleClear = () => {
    setElementData((prev) => ({
      ...prev,
      elementId: "",
      elementName: "",
      systemResult: "",
      claimData: "",
      feedback: "",
      comment: ""
    }));
  };

  useEffect(() => {
    if (isCorrect) {
      if (feedback) {
        handleUpdateFeedback();
      } else {
        handleFeedback();
      }
    }
    setIsCorrect(false);
  }, [isCorrect]);

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr className="font-InterBold text-ft15-18 text-black-10">
            <td colSpan={2} className="bg-darkGrey-30 py-3.5  pl-3.5">
              Commercial Usage
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="border-t border-grey-60 bg-white">
            <td colSpan="4">
              <div className="flex w-full justify-between  px-4 py-2">
                <div className=" flex w-full gap-3">
                  <div
                    onClick={() => setChecks(true)}
                    className={`flex h-8 w-max cursor-pointer items-center justify-center rounded-md border p-2 text-ft14-17 ${checks ? "border-black-0 text-black-10" : "border-grey-30 text-black-20"}`}
                  >
                    <span className=" font-InterMedium">Checks</span>
                  </div>
                  <div
                    onClick={() => setChecks(false)}
                    className={`flex h-8 w-max cursor-pointer items-center justify-center rounded-md border p-2 text-ft14-17 ${!checks ? "border-black-0 text-black-10" : "border-grey-30 text-black-20"}`}
                  >
                    <span className=" font-InterMedium">Description</span>
                  </div>
                </div>
                <div className="flex items-center justify-center gap-3">
                  <div>
                    <img
                      onClick={() =>
                        setImagePopup((prev) => ({
                          ...prev,
                          popup: true,
                          allImages: license,
                          idx: 0
                        }))
                      }
                      src="/images/image-logo.svg"
                      alt=""
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              {checks ? (
                <div className="flex w-full flex-col">
                  <div className="w-full px-4 py-2">
                    <div className="flex flex-col gap-3 rounded-md bg-grey-110 p-4">
                      <div className="flex items-center justify-between">
                        <span className="font-InterRegular text-ft15-18 text-black-10">
                          Is commercial usage allowed?
                        </span>
                        <div className="flex gap-2">
                          <span className="font-InterRegular text-ft15-18 text-black-10">
                            {data?.vehicleData?.commercialUsageAllowed
                              ?.claimData === "y"
                              ? "Yes"
                              : data?.vehicleData?.commercialUsageAllowed
                                    ?.claimData === "n"
                                ? "No"
                                : "NA"}
                          </span>
                          <div className="relative cursor-pointer">
                            {isCommerciallyUsedFeedback ? (
                              <>
                                {isCommerciallyUsedFeedback?.feedback !==
                                "CORRECT" ? (
                                  <img
                                    onClick={() => {
                                      setIsCorrect(false);
                                      handleFeedbackFn(
                                        data,
                                        "Is commercial usage allowed?",
                                        isCommerciallyUsedFeedback
                                      );
                                    }}
                                    src="/images/red-thumbs-down.svg"
                                    alt="Thumbs Down"
                                    className="cursor-pointer"
                                  />
                                ) : (
                                  <FaThumbsUp
                                    onClick={() => {
                                      setIsCorrect(false);
                                      handleFeedbackFn(
                                        data,
                                        "Is commercial usage allowed?",
                                        isCommerciallyUsedFeedback
                                      );
                                    }}
                                  />
                                )}
                                {openReasonPopup ===
                                  "Is commercial usage allowed?" && (
                                  <FeedbackPopup
                                    setState={() => setOpenReasonPopup(null)}
                                    elementData={elementData}
                                    setElementData={setElementData}
                                    handleFeedback={handleFeedback}
                                    handleUpdateFeedback={handleUpdateFeedback}
                                    edit={true}
                                    clearData={handleClear}
                                  />
                                )}
                              </>
                            ) : (
                              <div className="flex gap-2">
                                <LuThumbsUp
                                  onClick={() => {
                                    setIsCorrect(true);
                                    handleFeedbackFn(
                                      data,
                                      "Is commercial usage allowed?",
                                      null,
                                      true
                                    );
                                  }}
                                  className="cursor-pointer"
                                />
                                <LuThumbsDown
                                  onClick={() => {
                                    setIsCorrect(false);
                                    handleFeedbackFn(
                                      data,
                                      "Is commercial usage allowed?"
                                    );
                                  }}
                                  className="cursor-pointer"
                                />
                                {openReasonPopup ===
                                  "Is commercial usage allowed?" && (
                                  <FeedbackPopup
                                    setState={() => setOpenReasonPopup(null)}
                                    elementData={elementData}
                                    setElementData={setElementData}
                                    handleFeedback={handleFeedback}
                                    handleUpdateFeedback={handleUpdateFeedback}
                                    isForCorrect={isCorrect}
                                    callUpdate={feedback ? true : false}
                                    clearData={handleClear}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <span className="font-InterRegular text-ft15-18 text-black-10">
                          Is used commercially?
                        </span>
                        <div className="flex gap-2">
                          <span className="font-InterMedium text-ft15-18 text-black-10">
                            {data?.vehicleData?.commercialUsageAllowed
                              ?.systemData === "y"
                              ? "Yes"
                              : data?.vehicleData?.commercialUsageAllowed
                                    ?.systemData === "n"
                                ? "No"
                                : data?.vehicleData?.commercialUsageAllowed
                                    ?.systemData || "NA"}
                          </span>
                          <div className="relative cursor-pointer">
                            {isUsedCommerciallyFeedback ? (
                              <>
                                {isUsedCommerciallyFeedback?.feedback !==
                                "CORRECT" ? (
                                  <img
                                    onClick={() => {
                                      setIsCorrect(false);
                                      handleFeedbackFn(
                                        data,
                                        "Is used commercially?",
                                        isUsedCommerciallyFeedback
                                      );
                                    }}
                                    src="/images/red-thumbs-down.svg"
                                    alt=""
                                    className="cursor-pointer"
                                  />
                                ) : (
                                  <FaThumbsUp
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setIsCorrect(false);
                                      handleFeedbackFn(
                                        data,
                                        "Is used commercially?",
                                        isUsedCommerciallyFeedback
                                      );
                                    }}
                                  />
                                )}
                                {openReasonPopup ===
                                  "Is used commercially?" && (
                                  <FeedbackPopup
                                    setState={() => {
                                      setOpenReasonPopup(null);
                                    }}
                                    elementData={elementData}
                                    setElementData={setElementData}
                                    handleFeedback={handleFeedback}
                                    handleUpdateFeedback={handleUpdateFeedback}
                                    edit={true}
                                    clearData={handleClear}
                                  />
                                )}
                              </>
                            ) : (
                              <div className="flex gap-2">
                                <LuThumbsUp
                                  onClick={() => {
                                    setIsCorrect(true);
                                    handleFeedbackFn(
                                      data,
                                      "Is used commercially?",
                                      null,
                                      true
                                    );
                                  }}
                                  className="cursor-pointer"
                                />
                                <LuThumbsDown
                                  onClick={() => {
                                    setIsCorrect(false);
                                    handleFeedbackFn(
                                      data,
                                      "Is used commercially?"
                                    );
                                  }}
                                  className="cursor-pointer"
                                />
                                {openReasonPopup ===
                                  "Is used commercially?" && (
                                  <FeedbackPopup
                                    setState={() => {
                                      setOpenReasonPopup(null);
                                    }}
                                    elementData={elementData}
                                    setElementData={setElementData}
                                    handleFeedback={handleFeedback}
                                    handleUpdateFeedback={handleUpdateFeedback}
                                    callUpdate={feedback ? true : false}
                                    isForCorrect={isCorrect}
                                    clearData={handleClear}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mx-4 my-2 h-full rounded-md bg-grey-110 p-4 font-InterRegular text-ft14-17  text-black-10">
                  <span>
                    {data?.vehicleData?.commercialUsageAllowed?.description ||
                      "-"}
                  </span>
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CommercialFlowsResp;
