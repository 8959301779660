import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ClaimListComponent from "./modules/claimListNew";
import ClaimListHistory from "./modules/claimList";
import PrivateRoute from "./privateRoute";
import ClaimDetailsComponent from "./modules/claimDetails";
import ClaimDetailsComponentNew from "./modules/claimDetailsNew";
import OktaLogin from "./modules/login";
import ClaimReportComponent from "./modules/claimReport";
import ReportListComponent from "./modules/reportListNew";
import RepairFirstComponent from "./modules/repairFirst";
import CommercialUsageComponent from "./modules/commercialUsage";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<OktaLogin />} />
      <Route
        path="/claim-list"
        element={<PrivateRoute element={ClaimListComponent} />}
      />
      <Route
        path="/claim-history"
        element={<PrivateRoute element={ClaimListHistory} />}
      />
      <Route
        path="/claim/:claimNumber/:version/"
        element={
          <PrivateRoute element={ClaimDetailsComponent} bg={"bg-grey-40"} />
        }
      />
      <Route
        path="/claim-record/:claimNumber/:version/"
        element={
          <PrivateRoute element={ClaimDetailsComponentNew} bg={"bg-grey-40"} />
        }
      />
      <Route
        path="/repair-report"
        element={<PrivateRoute element={RepairFirstComponent} />}
      />
      <Route
        path="/claim-report"
        element={<PrivateRoute element={ClaimReportComponent} />}
      />
      <Route
        path="/report-list"
        element={<PrivateRoute element={ReportListComponent} />}
      />
      <Route
        path="/commercial-report"
        element={<PrivateRoute element={CommercialUsageComponent} />}
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Router;
