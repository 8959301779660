import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Utils from "../../utility";
import ClickOutside from "../../common/components/clickOutside";
import Dropdown from "../../common/components/dropdown";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const Report = ({
  setDate,
  setSelectTime,
  setSelectProcessedTime,
  selectTime,
  setProcessedDate,
  date,
  selectProcessedTime,
  processedDate,
  report
}) => {
  const [open, setOpen] = useState(false);
  const [openProcessed, setOpenProcessed] = useState(false);
  const navigate = useNavigate();
  const [range, setRange] = useState({
    startDate: null,
    endDate: new Date(""),
    key: "date"
  });

  const [rangeProcessed, setRangeProcessed] = useState({
    startDate: null,
    endDate: new Date(""),
    key: "selection"
  });

  const handleDateSelect = (ranges) => {
    const { startDate, endDate } = ranges.date;

    const formattedStartDate = Utils.formatDateYear(startDate);
    const formattedEndDate = Utils.formatDateYear(endDate);

    setRange({
      ...range,
      startDate: startDate,
      endDate: endDate
    });

    setDate((prev) => ({
      ...prev,
      startDate: formattedStartDate,
      endDate: formattedEndDate
    }));
  };

  const handleSelect = (value) => {
    setSelectTime(value);
    if (value === "Custom") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleSelectProcessed = (value) => {
    setSelectProcessedTime(value);
    if (value === "Custom") {
      setOpenProcessed(true);
    } else {
      setOpenProcessed(false);
    }
  };

  const handleClearFilters = () => {
    setSelectTime("All");

    setRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "date"
    });
    setSelectProcessedTime("All");
  };

  const handleDateSelectProcessed = (ranges) => {
    const { startDate, endDate } = ranges.selection;

    const formattedStartDate = Utils.formatDate(startDate);
    const formattedEndDate = Utils.formatDate(endDate);

    setRangeProcessed({
      ...rangeProcessed,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate
    });

    setProcessedDate((prev) => ({
      ...prev,
      startDate: formattedStartDate,
      endDate: formattedEndDate
    }));
  };

  const commercial = report?.elements?.find(
    (element) => element.elementName === "Is used commercially?"
  );

  const correct = commercial?.feedbacksCount?.CORRECT || 0;
  const incorrectAI = commercial?.feedbacksCount?.INCORRECT_AI_RESPONSE || 0;
  const incorrectData = commercial?.feedbacksCount?.INCORRECT_DATA_IMAGE || 0;
  const totalIncorrect = incorrectAI + incorrectData;
  const total = correct + totalIncorrect;

  const commercialAccuracy =
    total > 0 ? `${((correct / total) * 100)?.toFixed(2)}%` : "NA";

  const handleRedirect = (url) => {
    let newUrl = url;
    if (selectProcessedTime !== "Custom" && selectProcessedTime !== "All") {
      newUrl += `&processedTime=${selectProcessedTime}`;
    } else if (selectTime !== "Custom" && selectTime !== "All") {
      newUrl += `&selectTime=${selectTime}`;
    } else if (
      date?.endDate !== null &&
      date?.startDate !== null &&
      selectTime === "Custom" &&
      processedDate?.endDate !== null &&
      processedDate?.startDate !== null &&
      selectProcessedTime === "Custom"
    ) {
      newUrl += `&toDate=${date.startDate}&fromDate=${date.endDate}&selectTime=Custom`;
      newUrl += `&processedOnTo=${processedDate.startDate}&processedOnFrom=${processedDate.endDate}&processedTime=Custom`;
    } else if (
      date?.endDate !== null &&
      date?.startDate !== null &&
      selectTime === "Custom"
    ) {
      newUrl += `&toDate=${date.startDate}&fromDate=${date.endDate}&selectTime=Custom`;
    } else if (
      processedDate?.endDate !== null &&
      processedDate?.startDate !== null &&
      selectProcessedTime === "Custom"
    ) {
      newUrl += `&processedOnTo=${processedDate.startDate}&processedOnFrom=${processedDate.endDate}&processedTime=Custom`;
    }

    return newUrl;
  };

  return (
    <div>
      {" "}
      <div className="flex gap-3">
        <div className="flex items-center gap-2 pt-2">
          <span className="font-InterRegular text-ft20-28">Total Claims:</span>
          <span className="font-InterSemiBold text-ft25-30">
            {report?.claims}
          </span>
        </div>
        <div className="flex gap-2 pl-5">
          <ClickOutside isOpen={open} onClickOutside={() => setOpen(false)}>
            <div className="relative">
              <Dropdown
                width={"min-w-30"}
                text={"Date"}
                value={selectTime}
                list={["All", "Last 14 days", "Previous month", "Custom"]}
                handleSelect={handleSelect}
                setOpenCalendar={setOpen}
                startDate={date?.startDate}
                endDate={date?.endDate}
              />
              {open && (
                <div className="absolute z-[999]">
                  <DateRange
                    ranges={[range]}
                    onChange={handleDateSelect}
                    className="rounded-md border border-grey-30"
                    showDateDisplay={false}
                    showMonthAndYearPickers={false}
                  />
                </div>
              )}
            </div>
          </ClickOutside>
          <ClickOutside
            isOpen={openProcessed}
            onClickOutside={() => setOpenProcessed(false)}
          >
            <div className="relative">
              <Dropdown
                width={"min-w-30"}
                text={"Processed"}
                value={selectProcessedTime}
                list={["All", "Last 14 days", "Previous month", "Custom"]}
                handleSelect={handleSelectProcessed}
                setOpenCalendar={setOpenProcessed}
                startDate={processedDate?.startDate}
                endDate={processedDate?.endDate}
              />
              {openProcessed && (
                <div className="absolute z-[999]">
                  <DateRange
                    ranges={[rangeProcessed]}
                    onChange={handleDateSelectProcessed}
                    className="rounded-md border border-grey-30"
                    showDateDisplay={false}
                    showMonthAndYearPickers={false}
                  />
                </div>
              )}
            </div>
          </ClickOutside>
        </div>
        <div
          className="flex cursor-pointer items-center gap-1"
          onClick={handleClearFilters}
        >
          <span className="font-InterRegular text-ft14-17 text-blue-10">
            Clear
          </span>
          <img src="/images/clear.svg" alt="" />
        </div>
      </div>
      <div className="mt-5 grid w-full grid-cols-3 gap-5 xl:grid-cols-5">
        {Object.entries(report).map(([key, value]) => {
          if (
            key === "claims" ||
            key === "highestVersion" ||
            key === "withFeedback" ||
            key === "checks" ||
            key === "elements" ||
            key === "mismatched" ||
            key === "matched" ||
            key === "withoutFeedback" ||
            key === "correctFeedbackPercentage"
          )
            return;
          return (
            <div
              onClick={() => {
                if (key === "withoutFeedback")
                  navigate("/claim-history?withoutFeedback=true");
              }}
              key={key}
              className={`flex h-24 w-full flex-col gap-3 rounded-lg border border-grey-10 bg-grey-10 bg-opacity-20 p-3 ${key === "withoutFeedback" && "cursor-pointer"}`}
            >
              <span className="font-InterRegular text-ft16-24">
                {Utils.camelToTitleCase(key)}
              </span>
              <span className="font-InterSemiBold text-ft24-32">{value}</span>
            </div>
          );
        })}

        <div className="h-25 col-span-2 flex w-full flex-col gap-6 rounded-lg border border-grey-10 bg-grey-10 bg-opacity-20 p-3 xl:col-span-1 ">
          <div className="flex items-center justify-start gap-3">
            <span className="font-InterRegular text-ft16-24">
              Commercial Accuracy
            </span>
            <div className="flex gap-2">
              <span className="font-InterSemiBold text-ft16-24">
                {commercialAccuracy}
              </span>
            </div>
          </div>
          <div className="flex justify-start gap-3">
            <div className="flex gap-2">
              <span className="font-InterRegular text-ft16-24">Correct:</span>
              <span className="font-InterSemiBold text-ft16-24">
                {commercial?.feedbacksCount?.CORRECT || 0}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-InterRegular text-ft16-24">Incorrect:</span>
              <span className="font-InterSemiBold text-ft16-24">
                {totalIncorrect}
              </span>
            </div>
          </div>
        </div>

        {report?.elements?.length > 0 && (
          <div className="p- col-span-3 border border-grey-10 bg-grey-10 bg-opacity-20 xl:col-span-5">
            <table className="w-full">
              <thead>
                <tr className="sticky top-0  z-10 w-full bg-white font-InterBold text-ft10-18.15 text-black-10">
                  <td className="py-2 pl-2.5">Commercial Usage</td>
                  <td>Correct</td>
                  <td> Incorrect Data/Image</td>
                  <td>Incorrect AI Response</td>
                  <td>Accuracy</td>
                </tr>
              </thead>
              <tbody>
                {report?.elements?.map((item, idx) => {
                  if (
                    item?.elementName !== "Is used commercially?" &&
                    item?.elementName !== "Is commercial usage allowed?"
                  ) {
                    return;
                  }

                  return (
                    <tr
                      key={idx}
                      className="cursor-pointer border-t border-grey-30"
                    >
                      <td className="w-15per py-3.5 pl-2.5 font-InterRegular text-ft10-18.15 text-black-10">
                        {Utils.camelToTitleCase(item?.elementName)}
                      </td>
                      <td className="w-10per pl-2 font-InterRegular text-ft15-18 ">
                        <Link
                          className="text-blue-30 hover:underline"
                          to={handleRedirect(
                            `/claim-history?elementName=${item?.elementName}&elementFeedback=CORRECT`
                          )}
                        >
                          {item?.feedbacksCount?.CORRECT || 0}
                        </Link>
                      </td>
                      <td className="w-15per pl-2 font-InterRegular text-ft15-18 text-black-10">
                        <Link
                          to={handleRedirect(
                            `/claim-history?elementName=${item?.elementName}&elementFeedback=INCORRECT_DATA_IMAGE`
                          )}
                          className="text-blue-30 hover:underline"
                        >
                          {item?.feedbacksCount?.INCORRECT_DATA_IMAGE || 0}
                        </Link>
                      </td>
                      <td className="w-15per pl-2 font-InterRegular text-ft15-18 text-black-10">
                        <Link
                          to={handleRedirect(
                            `/claim-history?elementName=${item?.elementName}&elementFeedback=INCORRECT_AI_RESPONSE`
                          )}
                          className="text-blue-30 hover:underline"
                        >
                          {item?.feedbacksCount?.INCORRECT_AI_RESPONSE || 0}
                        </Link>
                      </td>
                      <td className="w-15per pl-2 font-InterRegular text-ft15-18 text-black-10">
                        {item.accuracyPercentage > 0
                          ? `${item.accuracyPercentage}%`
                          : 0}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {report?.elements?.length > 0 && (
          <div className="p- col-span-3 border border-grey-10 bg-grey-10 bg-opacity-20 xl:col-span-5">
            <table className="w-full">
              <thead>
                <tr className="sticky top-0  z-10 w-full bg-white font-InterBold text-ft10-18.15 text-black-10">
                  <td className="py-2 pl-2.5">VIN Comparison</td>
                  <td>Correct</td>
                  <td> Incorrect Data/Image</td>
                  <td>Incorrect AI Response</td>
                  <td>Accuracy</td>
                </tr>
              </thead>
              <tbody>
                {report?.elements?.map((item, idx) => {
                  if (
                    item?.elementName !== "Document VIN" &&
                    item?.elementName !== "Image VIN"
                  ) {
                    return;
                  }

                  return (
                    <tr
                      key={idx}
                      className="cursor-pointer border-t border-grey-30"
                    >
                      <td className="w-15per py-3.5 pl-2.5 font-InterRegular text-ft10-18.15 text-black-10">
                        {Utils.camelToTitleCase(item?.elementName)}
                      </td>
                      <td className="w-10per pl-2 font-InterRegular text-ft15-18 ">
                        <Link
                          className="text-blue-30 hover:underline"
                          to={handleRedirect(
                            `/claim-history?elementName=${item?.elementName}&elementFeedback=CORRECT`
                          )}
                        >
                          {item?.feedbacksCount?.CORRECT || 0}
                        </Link>
                      </td>
                      <td className="w-15per pl-2 font-InterRegular text-ft15-18 text-black-10">
                        <Link
                          to={handleRedirect(
                            `/claim-history?elementName=${item?.elementName}&elementFeedback=INCORRECT_DATA_IMAGE`
                          )}
                          className="text-blue-30 hover:underline"
                        >
                          {item?.feedbacksCount?.INCORRECT_DATA_IMAGE || 0}
                        </Link>
                      </td>
                      <td className="w-15per pl-2 font-InterRegular text-ft15-18 text-black-10">
                        <Link
                          to={handleRedirect(
                            `/claim-history?elementName=${item?.elementName}&elementFeedback=INCORRECT_AI_RESPONSE`
                          )}
                          className="text-blue-30 hover:underline"
                        >
                          {item?.feedbacksCount?.INCORRECT_AI_RESPONSE || 0}
                        </Link>
                      </td>
                      <td className="w-15per pl-2 font-InterRegular text-ft15-18 text-black-10">
                        {item.accuracyPercentage > 0
                          ? `${item.accuracyPercentage}%`
                          : 0}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {report?.checks?.length > 0 && (
          <div className="p- col-span-3 border border-grey-10 bg-grey-10 bg-opacity-20 xl:col-span-5">
            <table className="w-full">
              <thead>
                <tr className="sticky top-0  z-10 w-full bg-white font-InterBold text-ft10-18.15 text-black-10">
                  <td className="py-2 pl-2.5">
                    Mandatory Checks/Additional Questions
                  </td>
                  <td>Correct</td>
                  <td> Incorrect Data/Image</td>
                  <td>Incorrect AI Response</td>
                  <td>Accuracy</td>
                </tr>
              </thead>
              <tbody>
                {report?.checks?.map((item, idx) => (
                  <tr
                    key={idx}
                    className="cursor-pointer border-t border-grey-30"
                  >
                    <td className="w-15per py-3.5 pl-2.5 font-InterRegular text-ft10-18.15 text-black-10">
                      {Utils.camelToTitleCase(item?.checkName)}
                    </td>
                    <td className="w-10per pl-2 font-InterRegular text-ft15-18 ">
                      <Link
                        className="text-blue-30 hover:underline"
                        to={handleRedirect(
                          `/claim-history?checkName=${item?.checkName}&checkFeedback=CORRECT`
                        )}
                      >
                        {item?.feedbacksCount?.CORRECT || 0}
                      </Link>
                    </td>
                    <td className="w-15per pl-2 font-InterRegular text-ft15-18 text-black-10">
                      <Link
                        to={handleRedirect(
                          `/claim-history?checkName=${item?.checkName}&checkFeedback=INCORRECT_DATA_IMAGE`
                        )}
                        className="text-blue-30 hover:underline"
                      >
                        {item?.feedbacksCount?.INCORRECT_DATA_IMAGE || 0}
                      </Link>
                    </td>
                    <td className="w-15per pl-2 font-InterRegular text-ft15-18 text-black-10">
                      <Link
                        to={handleRedirect(
                          `/claim-history?checkName=${item?.checkName}&checkFeedback=INCORRECT_AI_RESPONSE`
                        )}
                        className="text-blue-30 hover:underline"
                      >
                        {item?.feedbacksCount?.INCORRECT_AI_RESPONSE || 0}
                      </Link>
                    </td>
                    <td className="w-15per pl-2 font-InterRegular text-ft15-18 text-black-10">
                      {item.accuracyPercentage > 0
                        ? `${item.accuracyPercentage}%`
                        : 0}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Report;
