import React, { useState, useEffect } from "react";
import Utils from "../../utility";
import { useLocation, useNavigate } from "react-router-dom";
import RepairList from "./repairList";
import { getClaimRepairList } from "../../services/zbrainConnectorService";
import { toast } from "react-toastify";

const RepairFirstComponent = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [report, setReport] = useState({});
  const pageSize = 50;
  const [totalDoc, setTotalDoc] = useState(0);
  const totalPages = Math.ceil(totalDoc / pageSize);
  const [debounce, setDebouncedSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(
    Number(queryParams.get("page")) || 1
  );
  const [date, setDate] = useState({
    startDate: queryParams.get("toDate") || null,
    endDate: queryParams.get("fromDate") || null
  });
  const [sortOrder, setSortOrder] = useState(queryParams.get("sort") || "desc");

  const [selectTime, setSelectTime] = useState("All");
  const [processedDate, setProcessedDate] = useState({
    startDate: queryParams.get("processedOnTo") || null,
    endDate: queryParams.get("processedOnFrom") || null
  });

  const [selectProcessedTime, setSelectProcessedTime] = useState(
    queryParams.get("processedTime") || "All"
  );
  const [commerciallyUsed, setCommercialUsed] = useState(
    queryParams.get("commercial") || "All"
  );
  const [versions, setVerisons] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(
    queryParams.get("v") || "All"
  );
  const [status, setStatus] = useState(queryParams.get("status") || "All");

  const getDateRange = (label) => {
    const now = new Date();
    switch (label) {
      case "Last 14 days":
        return {
          startDate: Utils.formatDateYear(
            new Date(now - 13 * 24 * 60 * 60 * 1000)
          ),
          endDate: Utils.formatDateYear(now)
        };
      case "Previous month": {
        const firstDayOfMonth = new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          1
        );
        const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 0);
        return {
          startDate: Utils.formatDateYear(firstDayOfMonth),
          endDate: Utils.formatDateYear(lastDayOfMonth)
        };
      }

      default:
        return null;
    }
  };

  const getDateRangeProcessed = (label) => {
    const now = new Date();

    const startOfDayUTC = (date) => {
      return Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        0,
        0,
        0,
        0
      );
    };

    const endOfDayUTC = (date) => {
      return Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        23,
        59,
        59,
        999
      );
    };

    switch (label) {
      case "Last 7 days":
        return {
          startDate: startOfDayUTC(
            new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000)
          ),
          endDate: endOfDayUTC(now)
        };
      case "Last 14 days":
        return {
          startDate: startOfDayUTC(
            new Date(now.getTime() - 13 * 24 * 60 * 60 * 1000)
          ),
          endDate: endOfDayUTC(now)
        };
      case "Previous month": {
        const firstDayOfMonth = new Date(
          now.getUTCFullYear(),
          now.getUTCMonth() - 1,
          1
        );
        const lastDayOfMonth = new Date(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          0
        );
        return {
          startDate: startOfDayUTC(firstDayOfMonth),
          endDate: endOfDayUTC(lastDayOfMonth)
        };
      }
      default:
        return null;
    }
  };

  const getClaims = async () => {
    try {
      let requesData = {
        claimNumber: debounce,
        limit: pageSize,
        page: currentPage,
        sortBy: sortOrder
      };
      let highestVersionArray = [];
      if (selectedVersion !== "All") {
        requesData.version = Number(selectedVersion.slice(1));
      }
      if (commerciallyUsed !== "All") {
        requesData.isCommerciallyUsed = commerciallyUsed === "Yes" ? "y" : "n";
      }
      if (status !== "All") {
        requesData.status = status;
      }
      if (selectTime !== "Custom" && selectTime !== "All") {
        const { startDate, endDate } = getDateRange(selectTime);
        requesData.fromDate = startDate;
        requesData.toDate = endDate;
      }
      if (date.startDate && date.endDate && selectTime === "Custom") {
        requesData.fromDate = date.startDate;
        requesData.toDate = date.endDate;
      }
      if (selectProcessedTime !== "Custom" && selectProcessedTime !== "All") {
        const { startDate, endDate } =
          getDateRangeProcessed(selectProcessedTime);
        requesData.processedOnFrom = startDate;
        requesData.processedOnTo = endDate;
      }
      if (
        processedDate.startDate &&
        processedDate.endDate &&
        selectProcessedTime === "Custom"
      ) {
        const startDateObject = new Date(
          processedDate.startDate.split("-").reverse().join("-")
        );
        const startUTCDate = Date.UTC(
          startDateObject.getUTCFullYear(),
          startDateObject.getUTCMonth(),
          startDateObject.getUTCDate(),
          0,
          0,
          0,
          0
        );
        requesData.processedOnFrom = startUTCDate;
        let endDateObject = new Date(
          processedDate.endDate.split("-").reverse().join("-")
        );
        const endUTCDate = Date.UTC(
          endDateObject.getUTCFullYear(),
          endDateObject.getUTCMonth(),
          endDateObject.getUTCDate(),
          23,
          59,
          59,
          999
        );

        requesData.processedOnTo = endUTCDate;
      }

      setLoading(true);
      const [error, response] = await Utils.parseResponse(
        getClaimRepairList(requesData)
      );

      if (error || !response) {
        if (error?.response?.status === 500) {
          toast.error("Internal server error");
        } else {
          toast.error(error?.response?.data?.message);
        }
        return;
      }
      for (let i = 1; i <= response.highestVersion; i++) {
        highestVersionArray.push(`v${i}`);
      }
      setVerisons(highestVersionArray);

      setTotalDoc(response.totalClaims);
      setReport(response);
      setList(response?.claims);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const updateDateParams = () => {
    if (
      date?.endDate !== null &&
      date?.startDate !== null &&
      selectTime === "Custom"
    ) {
      queryParams.set("toDate", date.startDate);
      queryParams.set("fromDate", date.endDate);
    } else {
      queryParams.delete("toDate");
      queryParams.delete("fromDate");
    }
  };

  const updateProcessedParams = () => {
    if (
      processedDate?.endDate !== null &&
      processedDate?.startDate !== null &&
      selectProcessedTime === "Custom"
    ) {
      queryParams.set("processedOnTo", processedDate.startDate);
      queryParams.set("processedOnFrom", processedDate.endDate);
    } else {
      queryParams.delete("processedOnTo");
      queryParams.delete("processedOnFrom");
    }
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handleLastPage = () => {
    handlePageChange(totalPages);
  };
  const updateQueryParams = (key, value) => {
    if (value) {
      queryParams.set(key, value);
    } else {
      queryParams.delete(key);
    }
  };

  useEffect(() => {
    updateDateParams();
    queryParams.set("selectTime", selectTime);
    queryParams.set("status", status);
    updateProcessedParams();
    queryParams.set("processedTime", selectProcessedTime);
    updateQueryParams("v", selectedVersion);
    updateQueryParams("commercial", commerciallyUsed);
    queryParams.set("sort", sortOrder);
    navigate(
      {
        search: queryParams.toString()
      },
      { replace: true }
    );
    getClaims();
  }, [
    selectTime,
    selectProcessedTime,
    date,
    processedDate,
    currentPage,
    commerciallyUsed,
    selectedVersion,
    sortOrder,
    debounce,
    status
  ]);

  return (
    <div className="flex flex-col gap-5">
      <RepairList
        date={date}
        processedDate={processedDate}
        selectProcessedTime={selectProcessedTime}
        selectTime={selectTime}
        setDate={setDate}
        setProcessedDate={setProcessedDate}
        setSelectProcessedTime={setSelectProcessedTime}
        setSelectTime={setSelectTime}
        loading={loading}
        list={list}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        setDebouncedSearch={setDebouncedSearch}
        debounce={debounce}
        handleFirstPage={handleFirstPage}
        handleLastPage={handleLastPage}
        report={report}
        setCommercialUsed={setCommercialUsed}
        commerciallyUsed={commerciallyUsed}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
        versions={versions}
        setCurrentPage={setCurrentPage}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        setStatus={setStatus}
        status={status}
      />
    </div>
  );
};

export default RepairFirstComponent;
