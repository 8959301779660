import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ClaimDetails from "./claimDetails";
import ImageZoom from "../claimDetails/imageZoom";
import RightPanel from "./rightPanel";
import Utils from "../../utility";
import {
  getClaimDetailsRecord,
  postClaimRecordFeedback,
  getImagesProcessed,
  updateRecordFeedback
} from "../../services/zbrainConnectorService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ClaimDetailsComponent = () => {
  const navigate = useNavigate();
  const [openImage, setImagePopup] = useState({
    popup: false,
    allImages: [],
    idx: null
  });
  const [openReasonPopup, setOpenReasonPopup] = useState(false);
  const [componentType, setComponentType] = useState("TIRE");
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const [feedbackData, setFeedbackData] = useState({});
  const [feedbackId, setFeedbackId] = useState(null);
  const [refractorImages, setRefractorImages] = useState([]);

  const [checkFeedback, setCheckFeedback] = useState({
    questionText: "",
    generatedResponse: "",
    feedback: "",
    comment: "",
    feedbackType: ""
  });

  const [componentFeedback, setComponentFeedback] = useState({
    componentName: "",
    questionText: "",
    generatedResponse: "",
    feedback: "",
    comment: "",
    feedbackType: ""
  });

  const { version, claimNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [images, setImages] = useState({});
  const [questions, setQuestions] = useState([]);
  const timeoutRef = useRef(null);

  const fetchDetails = async (
    retryCount = 1,
    currentVersion,
    currentClaimNumber
  ) => {
    if (retryCount > 4) return;

    if (retryCount === 1) {
      setLoading(true);
    }

    try {
      const requestData = { version: currentVersion };
      const [error, response] = await Utils.parseResponse(
        getClaimDetailsRecord(currentClaimNumber, requestData)
      );

      if (error || !response) {
        if (error?.response?.status === 500) {
          toast.error("Internal server error");
        } else {
          toast.error(error?.response?.data?.message || "Something went wrong");
        }
        navigate("/claim-list");
        return;
      }

      setData(response);
      setFeedbackData(response?.feedback);

      if (
        response?.vinComparisonResults?.processingStatus !== "Success" ||
        response?.commercialUsageResults?.processingStatus !== "Success" ||
        response?.components?.some(
          (item) => item.processingStatus !== "Success"
        )
      ) {
        timeoutRef.current = setTimeout(
          () =>
            fetchDetails(retryCount + 1, currentVersion, currentClaimNumber),
          30000
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleFeedback = async () => {
    try {
      let requesData = {
        claimId: data?.claimId,
        claimNumber: claimNumber,
        createdBy: user?.userId,
        version: version
      };

      const isElementDataEmpty = Object.values(checkFeedback).every(
        (value) => value === "" || value == null
      );

      if (!isElementDataEmpty) {
        const { feedbackType, ...rest } = checkFeedback;
        if (feedbackType === "commercialUsage") {
          requesData.commercialUsage = [rest];
        } else if (feedbackType === "vinComparison") {
          requesData.vinComparison = [rest];
        }
      }
      const isComponentDataEmpty = Object.values(componentFeedback).every(
        (value) => value === "" || value == null
      );

      if (!isComponentDataEmpty) {
        const { feedbackType, ...rest } = componentFeedback;
        if (feedbackType === "damageResults") {
          requesData.damageResults = [rest];
        } else if (feedbackType === "mandatoryChecks") {
          requesData.mandatoryChecks = [rest];
        } else if (feedbackType === "additionalQuestions") {
          requesData.additionalQuestions = [rest];
        }
      }

      const [error, response] = await Utils.parseResponse(
        postClaimRecordFeedback(requesData)
      );

      if (error || !response) {
        if (error?.response?.status === 500) {
          toast.error("Internal server error");
        } else {
          toast.error(error?.response?.data?.message);
        }
        return;
      }

      setFeedbackData(response);

      setCheckFeedback({
        questionText: "",
        generatedResponse: "",
        feedback: "",
        comment: "",
        feedbackType: ""
      });

      setComponentFeedback({
        componentName: "",
        questionText: "",
        generatedResponse: "",
        feedback: "",
        comment: "",
        feedbackType: ""
      });
      toast.success("Feedback added successfully");

      setFeedbackId(response?._id);
      setOpenReasonPopup(null);
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateFeedback = async () => {
    try {
      let requesData = {
        claimId: data?.claimId,
        claimNumber: claimNumber,
        feedbackType:
          checkFeedback?.feedbackType || componentFeedback.feedbackType,
        createdBy: user?.userId,
        version: version
      };
      const isElementDataEmpty = Object.values(checkFeedback).every(
        (value) => value === "" || value == null
      );

      if (!isElementDataEmpty) {
        const { feedbackType, ...rest } = checkFeedback;
        if (feedbackType === "commercialUsage") {
          requesData.commercialUsage = [rest];
        } else if (feedbackType === "vinComparison") {
          requesData.vinComparison = [rest];
        }
      }
      const isComponentDataEmpty = Object.values(componentFeedback).every(
        (value) => value === "" || value == null
      );

      if (!isComponentDataEmpty) {
        const { feedbackType, ...rest } = componentFeedback;
        if (feedbackType === "damageResults") {
          requesData.damageResults = [rest];
        } else if (feedbackType === "mandatoryChecks") {
          requesData.mandatoryChecks = [rest];
        } else if (feedbackType === "additionalQuestions") {
          requesData.additionalQuestions = [rest];
        }
      }
      const [error, response] = await Utils.parseResponse(
        updateRecordFeedback(requesData, data?.feedback?._id || feedbackId)
      );

      if (error || !response) {
        if (error?.response?.status === 500) {
          toast.error("Internal server error");
        } else {
          toast.error(error?.response?.data?.message);
        }
        return;
      }

      setFeedbackData(response);

      setCheckFeedback({
        questionText: "",
        generatedResponse: "",
        feedback: "",
        comment: "",
        feedbackType: ""
      });

      setComponentFeedback({
        componentName: "",
        questionText: "",
        generatedResponse: "",
        feedback: "",
        comment: "",
        feedbackType: ""
      });
      toast.success("Feedback updated successfully");

      setOpenReasonPopup(null);
    } catch (e) {
      console.error(e);
    }
  };

  const getInspectionImages = async () => {
    try {
      const requesData = {
        version
      };
      const [error, response] = await Utils.parseResponse(
        getImagesProcessed(claimNumber, requesData)
      );

      if (error || !response) {
        if (error?.response?.status === 500) {
          toast.error("Internal server error");
        } else {
          toast.error(error?.response?.data?.message);
        }
        return;
      }
      setImages(response);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getInspectionImages();
    // eslint-disable-next-line
  }, [version]);

  useEffect(() => {
    fetchDetails(1, version, claimNumber);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const mappedData = Object.keys(images).flatMap((key) =>
      images[key].length > 0
        ? images[key].map((image) => ({
            image: image?.signedObjectUrl,
            type: key,
            componentName: image?.componentName || key,
            componentCode: image?.componentCode
          }))
        : [{ image: null, type: key, componentName: key }]
    );
    setRefractorImages(mappedData);
  }, [images]);

  const handleBackNavigation = () => {
    if (location.key === "default") {
      navigate("/claim-list");
    } else {
      navigate(-1);
    }
  };

  if (loading) {
    return (
      <div className="flex h-75vh items-center justify-center">
        <div className="loader "></div>
      </div>
    );
  }
  return (
    <div>
      <div className="flex gap-2">
        <img
          onClick={handleBackNavigation}
          src="/images/left-arrow.svg"
          alt=""
          className="cursor-pointer"
        />
        <span className="font-InterBold text-ft25-30 text-black-10">
          Claim Report
        </span>
      </div>
      <div className="flex w-full gap-4 pt-6">
        <div className="w-80per">
          <ClaimDetails
            imagesData={refractorImages}
            setImagePopup={setImagePopup}
            handleFeedback={handleFeedback}
            handleUpdateFeedback={handleUpdateFeedback}
            openReasonPopup={openReasonPopup}
            setOpenReasonPopup={setOpenReasonPopup}
            data={data}
            setQuestions={setQuestions}
            questions={questions}
            setComponentType={setComponentType}
            componentType={componentType}
            feedbackData={feedbackData}
            setComponentFeedback={setComponentFeedback}
            componentFeedback={componentFeedback}
            setCheckFeedback={setCheckFeedback}
            checkFeedback={checkFeedback}
          />
        </div>
        <div className="w-20per">
          <RightPanel
            setImagePopup={setImagePopup}
            imagesData={refractorImages}
            handleFeedback={handleFeedback}
            handleUpdateFeedback={handleUpdateFeedback}
            openReasonPopup={openReasonPopup}
            setOpenReasonPopup={setOpenReasonPopup}
            data={data}
            setComponentType={setComponentType}
            componentType={componentType}
            feedbackData={feedbackData}
          />
        </div>
      </div>
      {openImage.popup && (
        <ImageZoom
          openImage={openImage}
          setImagePopup={setImagePopup}
          data={questions}
        />
      )}
    </div>
  );
};

export default ClaimDetailsComponent;
