import HTTPService from "../utility/httpService";
import { httpConstants, keyConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(keyConstants.TOKEN)}`
  };
}

const httpService = new HTTPService(
  process.env.REACT_APP_TW_CLAIM,
  getHeaders()
);

export const getClaimList = (requesData) => {
  const query = new URLSearchParams(requesData);
  return httpService.get(
    `${httpConstants.API_END_POINT.PROCESSED_CLAIMS_LIST}?${query}`,
    {},
    {},
    { ...getHeaders() }
  );
};
export const getClaimRecord = (requesData) => {
  const query = new URLSearchParams(requesData);
  return httpService.get(
    `${httpConstants.API_END_POINT.CLAIM_RECORD}?${query}`,
    {},
    {},
    { ...getHeaders() }
  );
};

export const postClaimFeedback = (requesData, id) => {
  return httpService.post(
    `${httpConstants.API_END_POINT.CLAIMS}/${id}${httpConstants.API_END_POINT.CLAIM_FEEDBACK}  `,
    requesData,
    {},
    { ...getHeaders() }
  );
};
export const postClaimRecordFeedback = (requesData) => {
  return httpService.post(
    `${httpConstants.API_END_POINT.CLAIM_RECORD}${httpConstants.API_END_POINT.CLAIM_FEEDBACK}  `,
    requesData,
    {},
    { ...getHeaders() }
  );
};

export const getClaimDetailsRecord = (id, requesData) => {
  const query = new URLSearchParams(requesData);
  return httpService.get(
    `${httpConstants.API_END_POINT.CLAIMS}${httpConstants.API_END_POINT.PROCESSED_CLAIM_DETAILS_RECORD}/${id}${httpConstants.API_END_POINT.PROCESSED_CLAIM_DETAILS}?${query}`,
    {},
    {},
    { ...getHeaders() }
  );
};

export const getClaimRepairList = (requesData) => {
  const query = new URLSearchParams(requesData);
  return httpService.get(
    `${httpConstants.API_END_POINT.CLAIMS}${httpConstants.API_END_POINT.PROCESSED_CLAIM_DETAILS_RECORD}${httpConstants.API_END_POINT.REPAIR}?${query}`,
    {},
    {},
    { ...getHeaders() }
  );
};

export const getClaimDetails = (id, requesData) => {
  const query = new URLSearchParams(requesData);
  return httpService.get(
    `${httpConstants.API_END_POINT.CLAIMS}/${id}${httpConstants.API_END_POINT.PROCESSED_CLAIM_DETAILS}?${query}`,
    {},
    {},
    { ...getHeaders() }
  );
};

export const updateFeedback = (requesData, id) => {
  return httpService.put(
    `${httpConstants.API_END_POINT.UPDATE_FEEDBACK}/${id}`,
    requesData,
    {},
    { ...getHeaders() }
  );
};

export const updateRecordFeedback = (requesData, id) => {
  return httpService.put(
    `${httpConstants.API_END_POINT.CLAIM_RECORD}${httpConstants.API_END_POINT.CLAIM_FEEDBACK}/${id}`,
    requesData,
    {},
    { ...getHeaders() }
  );
};

export const getImages = (id) => {
  return httpService.get(
    `${httpConstants.API_END_POINT.CLAIMS}/${id}${httpConstants.API_END_POINT.INSPECTION_IMAGES}`,
    {},
    {},
    { ...getHeaders() }
  );
};

export const getImagesProcessed = (id, requesData) => {
  const query = new URLSearchParams(requesData);
  return httpService.get(
    `${httpConstants.API_END_POINT.CLAIM_RECORD}/${id}${httpConstants.API_END_POINT.INSPECTION_IMAGES}?${query}`,
    {},
    {},
    { ...getHeaders() }
  );
};

export const getReport = (requesData) => {
  const query = new URLSearchParams(requesData);
  return httpService.get(
    `${httpConstants.API_END_POINT.CLAIMS}${httpConstants.API_END_POINT.REPORT}?${query}`,
    {},
    {},
    { ...getHeaders() }
  );
};

export const getReportList = (requesData) => {
  const query = new URLSearchParams(requesData);
  return httpService.get(
    `${httpConstants.API_END_POINT.CLAIM_RECORD}${httpConstants.API_END_POINT.REPORT}?${query}`,
    {},
    {},
    { ...getHeaders() }
  );
};

export const getNonCommercial = (requesData) => {
  const query = new URLSearchParams(requesData);
  return httpService.get(
    `${httpConstants.API_END_POINT.CLAIMS}${httpConstants.API_END_POINT.NON_COMMERCIAL}?${query}`,
    {},
    {},
    { ...getHeaders() }
  );
};
