import React, { useEffect, useState } from "react";
import { LuThumbsUp, LuThumbsDown } from "react-icons/lu";
import { FaThumbsUp } from "react-icons/fa6";

import FeedbackPopup from "../../common/components/popup/feedbackPopup";
import { keyConstants } from "../../constants";
import Utils from "../../utility";

const VinFlowResp = ({
  setImagePopup,
  imagesData,
  vinData,
  setOpenReasonPopup,
  feedback,
  handleClear,
  handleUpdateFeedback,
  handleFeedback,
  openReasonPopup,
  setCheckFeedback,
  checkFeedback,
  data
}) => {
  const [idx, setIdx] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);

  const tableData = [
    {
      name: "Claim VIN",
      claimData: vinData?.claimData || "-",
      systemData: "-",
      result: "-",
      showIcons: false,
      feedback: ""
    },
    {
      name: "Document VIN",
      claimData: vinData?.documentsVIN || "-",
      systemData: vinData?.documentAccuracy?.accuracy || "-",
      result: vinData?.documentAccuracy?.matched,
      showIcons: true,
      feedback: feedback?.vinComparison?.find(
        (item) => item.questionText === "Document VIN"
      )
    },
    {
      name: "Image VIN",
      claimData: vinData?.vehicleImagesVIN || "-",
      systemData: vinData?.vehicleVinAccuracy?.accuracy || "-",
      result: vinData?.vehicleVinAccuracy?.matched,
      showIcons: true,
      feedback: feedback?.vinComparison?.find(
        (item) => item.questionText === "Image VIN"
      )
    }
  ];

  const license = imagesData?.filter((item) => item.type === "vin");
  const documents = imagesData?.filter((item) => item.type === "documents");

  const handleFeedbackFn = (data, feedbackText, feedbackType, isCorrect) => {
    setCheckFeedback((prev) => ({
      ...prev,
      generatedResponse: "",
      questionText: "",
      feedback: "",
      comment: ""
    }));
    if (!isCorrect) {
      setOpenReasonPopup(feedbackText);
    }

    setCheckFeedback((prev) => ({
      ...prev,
      generatedResponse: data?.claimData || "",
      questionText: data?.name,
      feedback: isCorrect ? keyConstants.CORRECT : feedbackType?.feedback || "",
      comment: feedbackType?.comment || "",
      feedbackType: "vinComparison"
    }));
  };

  useEffect(() => {
    if (isCorrect) {
      if (feedback) {
        handleUpdateFeedback();
      } else {
        handleFeedback();
      }
    }
    setIsCorrect(false);
  }, [isCorrect]);

  return (
    <div>
      {data?.vinComparisonResults?.processingStatus !== "Success" ? (
        <div className="flex w-full flex-col justify-between bg-darkGrey-30 text-ft15-18 text-black-10">
          <div className=" px-1 py-3.5 font-InterBold">VIN Comparison</div>
          <div className="h-10 w-full bg-white px-1 py-2 text-center font-InterMedium  text-ft14-17">
            {Utils.convertCamelCaseToSentence(
              data?.vinComparisonResults?.processingStatus
            )}
          </div>
        </div>
      ) : (
        <div className="mt-4 w-full ">
          <div className="flex w-full items-center justify-between bg-darkGrey-30  px-3.5 text-ft15-18 text-black-10">
            <div className=" py-3.5 font-InterBold  ">VIN Comparison</div>
            <div className="flex items-center gap-5 font-InterMedium ">
              <div>
                <span className="font-InterBold ">Final Result - </span>
                <span
                  className={`${
                    vinData?.result === "Mismatched"
                      ? " text-red-10"
                      : " text-green-10"
                  }`}
                >
                  {vinData?.result}
                </span>
              </div>
            </div>
          </div>

          <table className="w-full rounded-[10px] bg-white">
            <thead>
              <tr className="font-InterBold text-ft15-18 text-black-10">
                <td className="py-3.5 pl-3.5"></td>
                <td className="border-l border-grey-60 py-3.5 pl-3.5">VIN</td>
                <td className="border-l border-grey-60 py-3.5 pl-3.5">
                  Confidance with claim VIN
                </td>
                <td className="border-l border-grey-60 py-3.5 pl-3.5">
                  Result
                </td>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item, index) => (
                <tr key={index} className="border-t border-grey-30">
                  <td className="w-20per py-3.5 pl-3.5 font-InterRegular text-ft10-18.15 text-grey-50">
                    <div>{item.name}</div>
                  </td>
                  <td className="w-30per border-l border-grey-60 pl-3.5 font-InterRegular text-ft10-18.15 text-black-10">
                    <div>{item.claimData}</div>
                  </td>
                  <td className="w-30per border-l border-grey-60 px-3.5 font-InterRegular text-ft10-18.15 text-black-10">
                    <div className="flex items-center justify-between">
                      <span>{item.systemData}</span>
                    </div>
                  </td>
                  <td className="w-25per border-l border-grey-60 p-3.5">
                    <div className="flex justify-between">
                      <div
                        className={`${
                          item.result === "Mismatched"
                            ? " text-red-10"
                            : " text-green-10"
                        } rounded-full font-InterMedium text-ft15-18`}
                      >
                        {item.result}
                      </div>
                      {item.showIcons && (
                        <div className="relative flex gap-2">
                          <img
                            onClick={() =>
                              setImagePopup((prev) => ({
                                ...prev,
                                popup: true,
                                allImages:
                                  item.name === "Document VIN"
                                    ? documents
                                    : license,
                                idx: 0
                              }))
                            }
                            src="/images/image-logo.svg"
                            alt=""
                            className="cursor-pointer"
                          />
                          {item?.feedback ? (
                            <div className="flex gap-2">
                              {item.feedback.feedback !==
                              keyConstants.CORRECT ? (
                                <div className="relative">
                                  <img
                                    onClick={() => {
                                      setIdx(index);
                                      setIsCorrect(false);
                                      handleFeedbackFn(
                                        item,
                                        item.name,
                                        item.feedback
                                      );
                                    }}
                                    src="/images/red-thumbs-down.svg"
                                    alt="Thumbs Down"
                                    className="cursor-pointer"
                                  />
                                  {openReasonPopup === item.name &&
                                    idx === index && (
                                      <FeedbackPopup
                                        setState={() => {
                                          setOpenReasonPopup(null);
                                          setIdx(null);
                                        }}
                                        elementData={checkFeedback}
                                        setElementData={setCheckFeedback}
                                        handleFeedback={handleFeedback}
                                        handleUpdateFeedback={
                                          handleUpdateFeedback
                                        }
                                        callUpdate={feedback ? true : false}
                                        edit={true}
                                        clearData={handleClear}
                                      />
                                    )}
                                </div>
                              ) : (
                                <div className="relative">
                                  <FaThumbsUp
                                    onClick={() => {
                                      setIdx(index);
                                      setIsCorrect(false);
                                      handleFeedbackFn(
                                        item,
                                        item.name,
                                        item.feedback
                                      );
                                    }}
                                    className="cursor-pointer"
                                  />
                                  {openReasonPopup === item.name &&
                                    idx === index && (
                                      <FeedbackPopup
                                        setState={() => {
                                          setOpenReasonPopup(null);
                                          setIdx(null);
                                        }}
                                        elementData={checkFeedback}
                                        setElementData={setCheckFeedback}
                                        handleFeedback={handleFeedback}
                                        handleUpdateFeedback={
                                          handleUpdateFeedback
                                        }
                                        callUpdate={feedback ? true : false}
                                        edit={true}
                                        clearData={handleClear}
                                      />
                                    )}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="flex gap-2">
                              <div className="relative">
                                <LuThumbsUp
                                  onClick={() => {
                                    setIdx(index);
                                    setIsCorrect(true);
                                    handleFeedbackFn(
                                      item,
                                      item.name,
                                      null,
                                      true
                                    );
                                  }}
                                  className="cursor-pointer"
                                />
                              </div>
                              <div className="relative">
                                <LuThumbsDown
                                  onClick={() => {
                                    setIdx(index);
                                    setIsCorrect(false);
                                    handleFeedbackFn(item, item.name);
                                  }}
                                  className="cursor-pointer"
                                />
                                {openReasonPopup === item.name &&
                                  idx === index && (
                                    <FeedbackPopup
                                      setState={() => {
                                        setOpenReasonPopup(null);
                                        setIdx(null);
                                      }}
                                      elementData={checkFeedback}
                                      setElementData={setCheckFeedback}
                                      handleFeedback={handleFeedback}
                                      handleUpdateFeedback={
                                        handleUpdateFeedback
                                      }
                                      callUpdate={feedback ? true : false}
                                      isForCorrect={isCorrect}
                                      clearData={handleClear}
                                    />
                                  )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default VinFlowResp;
