export const httpConstants = {
  METHOD_TYPE: { POST: "POST", PUT: "PUT", GET: "GET", DELETE: "DELETE" },
  CONTENT_TYPE: {
    APPLICATION_JSON: "application/json",
    MULTIPART_FORM_DATA: "multipart/form-data",
    APPLICATION_FORM_URLENCODED: "application/x-www-form-urlencoded",
    IMAGE_PNG: "image/png"
  },
  DEVICE_TYPE: { WEB: "web" },
  API_END_POINT: {
    PROCESSED_CLAIMS_LIST: "/claims",
    CLAIM_RECORD: "/claims/records",
    CLAIM_FEEDBACK: "/feedback",
    PROCESSED_CLAIM_DETAILS: "/details",
    REPAIR: "/repair",
    PROCESSED_CLAIM_DETAILS_RECORD: "/records",
    UPDATE_FEEDBACK: "/claims/feedback",
    INSPECTION_IMAGES: "/inspection-images",
    CLAIMS: "/claims",
    REPORT: "/report",
    NON_COMMERCIAL: "/non-commercial"
  }
};

export const formConstants = {
  ACTION_TYPES: {
    UDPATE_STATE: "UDPATE_STATE"
  }
};

export const keyConstants = {
  USER: "user",
  SCOPES: ["openid", "profile", "email"],
  TOKEN: "auth_token",
  INCORRECT_AI_RESPONSE: "INCORRECT_AI_RESPONSE",
  INCORRECT_DATA_IMAGE: "INCORRECT_DATA_IMAGE",
  CORRECT: "CORRECT"
};

export const tireComponent = {
  leftRearTire: "LEFT REAR TIRE",
  leftFrontTire: "LEFT FRONT TIRE",
  rightRearTire: "RIGHT REAR TIRE",
  rightFrontTire: "RIGHT FRONT TIRE",
  leftRearWheel: "LEFT REAR WHEEL",
  leftFrontWheel: "LEFT FRONT WHEEL",
  rightRearWheel: "RIGHT REAR WHEEL",
  rightFrontWheel: "RIGHT FRONT WHEEL"
};
