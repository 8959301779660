import React, { useEffect, useState } from "react";
import ComparisonList from "./comparisonList";
import moment from "moment";

const ClaimDetails = ({
  imagesData,
  setImagePopup,
  openReasonPopup,
  handleFeedback,
  setOpenReasonPopup,
  data,
  handleUpdateFeedback,
  setQuestions,
  allImages,
  questions,
  setComponentType,
  componentType,
  feedbackData,
  setComponentFeedback,
  componentFeedback,
  setCheckFeedback,
  checkFeedback
}) => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (data) {
      if (componentType === "TIRE") {
        setValues([
          {
            name: "Contract Number:",
            value: data?.contractNumber || "NA"
          },
          {
            name: "VIN:",
            value: data?.vin || "NA"
          },
          {
            name: "Tire Curb Allowed:",
            value:
              data?.tireContractCoverage?.tireCurbAllowed === "y"
                ? "Yes"
                : data?.tireContractCoverage?.tireCurbAllowed === "n"
                  ? "No"
                  : "-"
          },
          {
            name: "RO Number:",
            value: data?.roNumber
          },
          {
            name: "Tire Defect Allowed:",
            value:
              data?.tireContractCoverage?.tireDefectAllowed === "y"
                ? "Yes"
                : data?.tireContractCoverage?.tireDefectAllowed === "n"
                  ? "No"
                  : "-"
          },
          {
            name: "Claim Odometer:",
            value: data?.claimOdometer
          },
          {
            name: "Tire Repair First:",
            value:
              data?.tireContractCoverage?.tireRepairFirst === "y"
                ? "Yes"
                : data?.tireContractCoverage?.tireRepairFirst === "n"
                  ? "No"
                  : "-"
          },
          {
            name: "IsNis Claim:",
            value:
              data?.isNisClaim === "y"
                ? "Yes"
                : data?.isNisClaim === "n"
                  ? "No"
                  : "-"
          },
          {
            name: "Tire Replacement Only:",
            value:
              data?.tireContractCoverage?.tireReplacemenOnly === "y"
                ? "Yes"
                : data?.tireContractCoverage?.tireReplacemenOnly === "n"
                  ? "No"
                  : "-"
          }
        ]);
      } else {
        setValues([
          {
            name: "Contract Number:",
            value: data?.contractNumber || "NA"
          },
          {
            name: "VIN:",
            value: data?.vin || "NA"
          },
          {
            name: "Wheel Curb Allowed:",
            value:
              data?.wheelContractCoverage?.wheelCurbAllowed === "y"
                ? "Yes"
                : data?.wheelContractCoverage?.wheelCurbAllowed === "n"
                  ? "No"
                  : "-"
          },
          {
            name: "RO Number:",
            value: data?.roNumber
          },
          {
            name: "Wheel Defect Allowed:",
            value:
              data?.wheelContractCoverage?.wheelDefectAllowed === "y"
                ? "Yes"
                : data?.wheelContractCoverage?.wheelDefectAllowed === "n"
                  ? "No"
                  : "-"
          },
          {
            name: "Claim Odometer:",
            value: data?.claimOdometer
          },
          {
            name: "Wheel Repair First:",
            value:
              data?.wheelContractCoverage?.wheelRepairFirst === "y"
                ? "Yes"
                : data?.wheelContractCoverage?.wheelRepairFirst === "n"
                  ? "No"
                  : "-"
          },
          {
            name: "IsNis Claim:",
            value:
              data?.isNisClaim === "y"
                ? "Yes"
                : data?.isNisClaim === "n"
                  ? "No"
                  : "-"
          },
          {
            name: "Wheel Replacement Only:",
            value:
              data?.wheelContractCoverage?.wheelReplacementOnly === "y"
                ? "Yes"
                : data?.wheelContractCoverage?.wheelReplacementOnly === "n"
                  ? "No"
                  : "-"
          }
        ]);
      }
    }
    //eslint-disable-next-line
  }, [data, componentType]);

  return (
    <div className="flex flex-col gap-6">
      <div className="size-full rounded-[10px] bg-white px-5 py-6">
        <div className="flex justify-between">
          <span className="font-InterSemiBold text-ft15-18 text-black-10">
            Claim Number
          </span>
          <div className="flex w-auto flex-col gap-1 pr-3">
            <div className="flex w-full justify-between whitespace-nowrap font-InterRegular text-ft15-18 text-grey-20">
              <span className="w-1/3">Created on</span>
              <span className="w-2/3">
                {moment(data?.createdDateTime).format(
                  "D MMMM YYYY [at] h:mm A"
                )}
              </span>
            </div>
            <div className="flex w-full justify-between whitespace-nowrap font-InterRegular text-ft15-18 text-grey-20">
              <span className="w-2/5">Processed on</span>
              <span className="w-3/5">
                {data?.processedOn
                  ? moment(data?.processedOn).format("D MMMM YYYY [at] h:mm A")
                  : "-"}
              </span>
            </div>
          </div>
        </div>
        <div className="flex gap-3">
          <span className="font-InterMedium text-ft25-30 text-black-10">
            {data?.claimNumber}
          </span>
          <span
            className={`flex h-6 items-center rounded-[5px] ${data?.contractAgent?.agentCode === "001500" ? "bg-red-10 text-white" : "bg-darkGrey-20 text-darkGrey-10"} px-2 font-InterRegular text-ft14-17 `}
          >
            Program Name: {data?.contractAgent?.agentName}
          </span>
          <span
            className={`flex h-6 items-center rounded-[5px] ${data?.contractAgent?.agentCode === "001500" ? "bg-red-10 text-white" : "bg-darkGrey-20 text-darkGrey-10"} px-2 font-InterRegular text-ft14-17`}
          >
            Program Code: {data?.contractAgent?.agentCode}
          </span>
        </div>
        <div className="pt-3">
          <span className="font-InterSemiBold text-ft15-18 text-black-10">
            Contract Details
          </span>
        </div>
        <div className="flex w-80per flex-wrap justify-between gap-y-2.5 pt-2.5">
          {values.map((item, idx) => (
            <div key={idx} className="flex w-1/2 justify-between">
              <span className="w-1/2 font-InterRegular text-ft15-18 text-grey-50">
                {item.name}
              </span>
              <span className="w-1/2 font-InterMedium text-ft15-18 text-black-10">
                {item.value}
              </span>
            </div>
          ))}
        </div>
      </div>
      <ComparisonList
        imagesData={imagesData}
        setImagePopup={setImagePopup}
        openReasonPopup={openReasonPopup}
        handleFeedback={handleFeedback}
        handleUpdateFeedback={handleUpdateFeedback}
        setOpenReasonPopup={setOpenReasonPopup}
        data={data}
        setQuestions={setQuestions}
        allImages={allImages}
        questions={questions}
        setComponentType={setComponentType}
        componentType={componentType}
        localFeedbackData={feedbackData}
        setComponentFeedback={setComponentFeedback}
        componentFeedback={componentFeedback}
        setCheckFeedback={setCheckFeedback}
        checkFeedback={checkFeedback}
      />
    </div>
  );
};

export default ClaimDetails;
