import React, { useEffect, useState } from "react";
// import FeedbackPopup from "../../common/components/popup/feedbackPopup";
import CommercialFlowsResp from "./commercialFlowResp";
// import { FaThumbsUp } from "react-icons/fa6";
// import { LuThumbsUp, LuThumbsDown } from "react-icons/lu";
import ComponentTable from "./componentTable";
// import { v4 as uuidv4 } from "uuid";
// import { keyConstants } from "../../constants";
import VinFlowResp from "./vinFlowResp";

// const initialListData = [
//   {
//     componentName: "VIN",
//     claimData: "",
//     systemData: "",
//     result: "",
//     feedback: ""
//   },
//   {
//     componentName: "Customer Last Name",
//     claimData: "",
//     systemData: "",
//     result: "",
//     feedback: ""
//   },
//   {
//     componentName: "Customer Address",
//     claimData: "",
//     systemData: "",
//     result: "",
//     feedback: ""
//   },
//   {
//     componentName: "Odometer Reading",
//     claimData: "",
//     systemData: "",
//     result: "",
//     feedback: ""
//   },
//   {
//     componentName: "Is the difference is <=50 ",
//     claimData: "",
//     systemData: "",
//     result: "",
//     feedback: ""
//   }
// ];
const ComparisonList = ({
  imagesData,
  setImagePopup,
  openReasonPopup,
  setElementData,
  elementData,
  handleFeedback,
  setOpenReasonPopup,
  data,
  handleUpdateFeedback,
  setQuestions,
  questions,
  setCheckData,
  checkData,
  setComponentType,
  componentType,
  localFeedbackData
}) => {
  const [hoverId, setHoverId] = useState(null);
  const [comparisonData, setComparisonData] = useState([]);
  // const [componentData, setComponentData] = useState(initialListData);
  const [vinData, setVinData] = useState(null);

  let uniqueIdCounter = 1;
  // const dataMapping = {
  //   vin: 0,
  //   customerLastName: 2,
  //   customerAddress: 3,
  //   odoMeterReading: 4,
  //   odoMeterDifference: 5
  // };

  // const mapVehicleDataToTbody = (vehicleData, customerData) => {
  //   const combinedData = { ...vehicleData, ...customerData };

  //   return initialListData.map((item, index) => {
  //     const dataKey = Object.keys(dataMapping).find(
  //       (key) => dataMapping[key] === index
  //     );

  //     const vehicleItem = combinedData[dataKey] || {};
  //     const matchingFeedback = localFeedbackData?.elementsFeedback?.find(
  //       (feedback) =>
  //         feedback.elementName.toLowerCase() ===
  //         item.componentName.toLowerCase()
  //     );

  //     return {
  //       _id: uniqueIdCounter++,
  //       componentName: item.componentName,
  //       claimData: vehicleItem.claimData || item.claimData || "NA",
  //       systemResults: vehicleItem.systemData || item.systemData || "NA",
  //       result: vehicleItem.result || item.result,
  //       feedback: matchingFeedback ? matchingFeedback : item.feedback
  //     };
  //   });
  // };

  const extractVindata = (vehicleData) => {
    setVinData(vehicleData?.vin);
  };

  const mapComponentsToTableData = (components) => {
    return components?.map((component) => {
      const claimDataApprove = component?.sgComponentStatus;

      const isSystemResultApproved =
        component?.componentTypeLocation?.componentStatus;

      const thead = [
        {
          name: component.componentName
        },
        {
          name: claimDataApprove
        },
        {
          name: isSystemResultApproved,
          componentName: component.componentName,
          componentId: component?._id,
          moreSpecification:
            component?.componentTypeLocation?.componentTypeData
              ?.mandatoryChecks,
          isMoreDetailsAvaialble: true,
          reason: component?.componentTypeLocation?.componentDescription,
          feedback: localFeedbackData?.checks
        },
        {
          name:
            isSystemResultApproved === claimDataApprove
              ? "Matched"
              : "Mismatched",
          status: true
        }
      ];

      const tbody = [];
      const damageDescription =
        component.componentTypeLocation?.componentTypeData
          ?.componentDamageDescription;

      if (damageDescription) {
        Object.keys(damageDescription).forEach((damageType) => {
          const damage = damageDescription[damageType];
          if (damage.isDamageIdentified) {
            setQuestions((prev) => ({
              ...prev,
              [component.componentName]: damage?.componentAdditionalQuestions
            }));

            const matchingFeedback = localFeedbackData?.elementsFeedback?.find(
              (feedback) =>
                feedback.elementName + " " + feedback.componentName ===
                "Tire Damage Description" + " " + component.componentName
            );

            tbody.push({
              _id: uniqueIdCounter++,
              element: `${componentType === "TIRE" ? "Tire" : "Wheel"} Damage Description`,
              componentName: component.componentName,
              claimData:
                component.tireDamageDescription ||
                component?.wheelDamageDescription ||
                "-",
              systemResults: `${component.tireDamageDescription || component?.wheelDamageDescription || "-"} ${
                damage?.isDamageIdentified === "Yes"
                  ? "identified"
                  : "not identified"
              }`,
              result:
                damage?.isDamageIdentified === "Yes" ? "Matched" : "Mismatched",
              feedback: matchingFeedback ? matchingFeedback : ""
            });
          }
        });
      }
      const matchingFeedback = localFeedbackData?.elementsFeedback?.find(
        (feedback) =>
          feedback.elementName + " " + feedback.componentName ===
          "Corrective Action" + " " + component.componentName
      );

      tbody.push({
        _id: uniqueIdCounter++,
        element: "Corrective Action",
        componentName: component.componentName,
        claimData: component.componentTypeLocation?.claimDataAction || "-",
        systemResults: component.componentTypeLocation?.componentAction || "-",
        result:
          component.componentTypeLocation?.claimDataAction ===
          component.componentTypeLocation?.componentAction
            ? "Matched"
            : "Mismatched",
        feedback: matchingFeedback ? matchingFeedback : ""
      });
      tbody.push({
        mandatoryChecks:
          component?.componentTypeLocation?.componentTypeData?.mandatoryChecks,
        componentName: component.componentName,
        isMoreDetailsAvaialble: true,
        reason: component?.componentTypeLocation?.componentDescription,
        systemResult: isSystemResultApproved,
        feedback: localFeedbackData?.checks,
        componentId: component?._id
      });

      return { thead, tbody };
    });
  };

  useEffect(() => {
    // let mappedData = [];
    // if (data?.vehicleData) {
    //   mappedData = [
    //     ...mappedData,
    //     ...mapVehicleDataToTbody(
    //       data?.vehicleData,
    //       data.customer,
    //       data.feedback || []
    //     )
    //   ];
    // }
    // if (mappedData?.length > 0) setComponentData(mappedData);
    extractVindata(data?.vehicleData);
    //eslint-disable-next-line
  }, [data, localFeedbackData]);

  useEffect(() => {
    let tableData;
    const componentTireData = data?.components?.filter(
      (item) => item.componentType?.toLowerCase() === "tire"
    );

    const componentWheelData = data?.components?.filter(
      (item) => item.componentType?.toLowerCase() === "wheel"
    );
    if (data?.components?.length > 0 && data?.components) {
      if (componentType === "TIRE") {
        tableData = mapComponentsToTableData(
          componentTireData,
          data.feedback || []
        );
      } else {
        tableData = mapComponentsToTableData(
          componentWheelData,
          data.feedback || []
        );
      }
    }

    setComparisonData(tableData);
  }, [data, componentType, localFeedbackData]);

  // const claimData = imagesData?.filter((item) => item.type !== "damage");

  return (
    <div>
      {/* <div>
        <h1 className="font-InterBold text-ft20-28 text-black-10">
          Comparison
        </h1>
      </div> */}
      <div className="w-full ">
        {/* <table className="w-full rounded-[10px] bg-white">
          <thead>
            <tr className="font-InterBold text-ft15-18 text-black-10">
              <td className="py-3.5 pl-3.5"></td>
              <td className="border-l border-grey-60 py-3.5 pl-3.5">
                Claim Data
              </td>
              <td className="border-l border-grey-60 py-3.5 pl-3.5">
                System Results
              </td>
              <td className="border-l border-grey-60 py-3.5 pl-3.5">Result</td>
            </tr>
          </thead> */}

        {/* <ComparisonBody
            openReasonPopup={openReasonPopup}
            setImagePopup={setImagePopup}
            setOpenReasonPopup={setOpenReasonPopup}
            data={componentData}
            hoverId={hoverId}
            imagesData={claimData}
            setHoverId={setHoverId}
            setElementData={setElementData}
            elementData={elementData}
            handleFeedback={handleFeedback}
            handleUpdateFeedback={handleUpdateFeedback}
            feedback={localFeedbackData}
            setCheckData={setCheckData}
          /> */}
        {/* </table> */}

        <div>
          <CommercialFlowsResp
            data={data}
            elementData={elementData}
            setElementData={setElementData}
            handleFeedback={handleFeedback}
            imagesData={imagesData}
            setImagePopup={setImagePopup}
            handleUpdateFeedback={handleUpdateFeedback}
            feedback={localFeedbackData}
            openReasonPopup={openReasonPopup}
            setOpenReasonPopup={setOpenReasonPopup}
            setCheckData={setCheckData}
          />
        </div>
        <div className="my-3">
          <VinFlowResp
            data={data}
            elementData={elementData}
            setElementData={setElementData}
            handleFeedback={handleFeedback}
            imagesData={imagesData}
            setImagePopup={setImagePopup}
            handleUpdateFeedback={handleUpdateFeedback}
            feedback={localFeedbackData}
            openReasonPopup={openReasonPopup}
            setOpenReasonPopup={setOpenReasonPopup}
            setCheckData={setCheckData}
            vinData={vinData}
          />
        </div>
        <div className=" my-3 flex h-10 items-center gap-5">
          {data?.components?.find(
            (item) => item.componentType?.toLowerCase() === "tire"
          ) && (
            <span
              onClick={() => setComponentType("TIRE")}
              className={` ${componentType === "TIRE" ? "border border-green-10 bg-green-10" : "border border-grey-10 bg-white"} w-20 cursor-pointer rounded-lg bg-opacity-10 py-2 text-center font-InterMedium text-ft16-24 text-black-0`}
            >
              Tire
            </span>
          )}
          {data?.components?.find(
            (item) => item.componentType?.toLowerCase() === "wheel"
          ) && (
            <span
              onClick={() => setComponentType("WHEEL")}
              className={` ${componentType === "WHEEL" ? "border border-green-10 bg-green-10" : "border border-grey-10 bg-white"} w-20 cursor-pointer rounded-lg bg-opacity-10 py-2 text-center font-InterMedium text-ft16-24 text-black-0`}
            >
              Wheel
            </span>
          )}
        </div>
        {comparisonData?.map((item, idx) => (
          <div key={idx}>
            <ComponentTable
              openReasonPopup={openReasonPopup}
              setImagePopup={setImagePopup}
              setOpenReasonPopup={setOpenReasonPopup}
              tbody={item.tbody}
              thead={item.thead}
              hoverId={hoverId}
              imagesData={imagesData}
              setHoverId={setHoverId}
              setElementData={setElementData}
              elementData={elementData}
              handleFeedback={handleFeedback}
              handleUpdateFeedback={handleUpdateFeedback}
              feedback={localFeedbackData}
              questions={questions}
              checkData={checkData}
              setCheckData={setCheckData}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComparisonList;

// const ComparisonBody = ({
//   data,
//   setImagePopup,
//   setOpenReasonPopup,
//   openReasonPopup,
//   setHoverId,
//   hoverId,
//   imagesData,
//   setElementData,
//   elementData,
//   handleFeedback,
//   handleUpdateFeedback,
//   feedback,
//   setCheckData
// }) => {
//   const [isCorrect, setIsCorrect] = useState(false);

//   const handleFeedbackFn = (item) => {
//     setCheckData((prev) => ({
//       ...prev,
//       componentName: "",
//       componentId: "",
//       checkName: "",
//       feedback: "",
//       comment: "",
//       checkId: "",
//       systemResult: ""
//     }));
//     setOpenReasonPopup(item._id);
//     setElementData((prev) => ({
//       ...prev,
//       elementId: item?.feedback?.elementId,
//       elementName: item?.feedback?.elementName,
//       systemResult: item?.feedback?.systemResults,
//       claimData: item?.feedback?.claimData,
//       feedback: item.feedback.feedback,
//       comment: item?.feedback?.comment
//     }));
//   };

//   const handleClear = () => {
//     setElementData((prev) => ({
//       ...prev,
//       elementId: "",
//       elementName: "",
//       systemResult: "",
//       claimData: "",
//       feedback: "",
//       comment: ""
//     }));
//   };

//   useEffect(() => {
//     if (isCorrect) {
//       if (feedback) {
//         handleUpdateFeedback();
//       } else {
//         handleFeedback();
//       }
//     }
//     setIsCorrect(false);
//   }, [isCorrect]);
//   return (
//     <tbody>
//       {data?.map((item, idx) => (
//         <tr key={idx} className="border-t border-grey-30">
//           <td className="w-25per py-3.5 pl-3.5 font-InterRegular text-ft10-18.15 text-grey-50">
//             <div>{item.componentName}</div>
//           </td>
//           <td className="w-30per border-l border-grey-60 pl-3.5 font-InterRegular text-ft10-18.15 text-black-10">
//             <div>
//               {item.claimData?.toLowerCase() === "n"
//                 ? "No"
//                 : item?.claimData?.toLowerCase() === "y"
//                   ? "Yes"
//                   : item.claimData}
//             </div>
//           </td>
//           <td
//             onMouseEnter={() => {
//               if (!openReasonPopup) setHoverId(item._id);
//             }}
//             onMouseLeave={() => {
//               if (!openReasonPopup) setHoverId(null);
//             }}
//             className="w-30per border-l border-grey-60 px-3.5 font-InterRegular text-ft10-18.15 text-black-10"
//           >
//             <div className="flex items-center justify-between">
//               <span>
//                 {item.systemResults?.toLowerCase() === "n"
//                   ? "No"
//                   : item.systemResults?.toLowerCase() === "y"
//                     ? "Yes"
//                     : item.systemResults}
//               </span>
//               {item?.feedback && (
//                 <div className="relative flex gap-3">
//                   {hoverId === item._id && item?.feedback && (
//                     <img
//                       onClick={() =>
//                         setImagePopup((prev) => ({
//                           ...prev,
//                           popup: true,
//                           allImages: imagesData,
//                           idx: 0
//                         }))
//                       }
//                       src="/images/image-logo.svg"
//                       alt=""
//                       className="cursor-pointer"
//                     />
//                   )}
//                   {item.feedback.feedback !== "CORRECT" ? (
//                     <img
//                       onClick={() => {
//                         setIsCorrect(false);
//                         handleFeedbackFn(item);
//                       }}
//                       src="/images/red-thumbs-down.svg"
//                       alt=""
//                       className="cursor-pointer"
//                     />
//                   ) : (
//                     <FaThumbsUp
//                       onClick={() => {
//                         setIsCorrect(false);
//                         handleFeedbackFn(item);
//                       }}
//                       className="cursor-pointer"
//                     />
//                   )}
//                   {openReasonPopup === item._id && (
//                     <FeedbackPopup
//                       setState={() => setOpenReasonPopup(null)}
//                       elementData={elementData}
//                       setElementData={setElementData}
//                       handleFeedback={handleFeedback}
//                       handleUpdateFeedback={handleUpdateFeedback}
//                       edit={true}
//                       clearData={handleClear}
//                     />
//                   )}
//                 </div>
//               )}
//               {hoverId === item._id && !item?.feedback && (
//                 <div className="relative flex gap-3">
//                   <img
//                     onClick={() =>
//                       setImagePopup((prev) => ({
//                         ...prev,
//                         popup: true,
//                         allImages: imagesData,
//                         idx: 0
//                       }))
//                     }
//                     src="/images/image-logo.svg"
//                     alt=""
//                     className="cursor-pointer"
//                   />
//                   <LuThumbsUp
//                     onClick={() => {
//                       setIsCorrect(true);
//                       setElementData((prev) => ({
//                         ...prev,
//                         elementId: uuidv4(),
//                         elementName: item?.componentName,
//                         systemResult: item.systemResults,
//                         claimData: item.claimData,
//                         feedback: keyConstants.CORRECT
//                       }));
//                     }}
//                     className="cursor-pointer"
//                   />
//                   <LuThumbsDown
//                     onClick={() => {
//                       setIsCorrect(false);
//                       setOpenReasonPopup(item._id);
//                       setElementData((prev) => ({
//                         ...prev,
//                         elementId: uuidv4(),
//                         elementName: item?.componentName,
//                         systemResult: item.systemResults,
//                         claimData: item.claimData
//                       }));
//                     }}
//                     className="cursor-pointer"
//                   />
//                   {openReasonPopup === item._id && (
//                     <FeedbackPopup
//                       setState={() => setOpenReasonPopup(null)}
//                       elementData={elementData}
//                       setElementData={setElementData}
//                       handleFeedback={handleFeedback}
//                       handleUpdateFeedback={handleUpdateFeedback}
//                       callUpdate={feedback ? true : false}
//                       isForCorrect={isCorrect}
//                       clearData={handleClear}
//                     />
//                   )}
//                 </div>
//               )}
//             </div>
//           </td>
//           <td className="w-15per border-l border-grey-60 p-3.5">
//             <div
//               className={`${
//                 item.result === "Mismatched" ? " text-red-10" : " text-green-10"
//               } rounded-full font-InterMedium text-ft15-18`}
//             >
//               {item.result || "-"}
//             </div>
//           </td>
//         </tr>
//       ))}
//     </tbody>
//   );
// };
